import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import ReactSVG from 'react-svg'
import { Typography, Divider } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'

export const COURSE_TYPE = {
    GROUP: 'group',
    PRIVATE: 'private',
    LUNCH: 'lunch',
}

class TicketCard extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { classes, t, ticket, onClickTicket } = this.props

        const levelCode = ticket.getLevelCode()

        let cardHeaderStyle
        let cardBodyTextStyle
        let ticketIcon
        if (ticket.isGroup()) {
            cardHeaderStyle = [classes.courseHeader, classes.groupCourseHeader].join(' ')
            cardBodyTextStyle = [classes.cardBodyText, classes.groupCourseHeaderText].join(' ')
            ticketIcon = <ReactSVG src={'/assets/icons/light/group_lesson.svg'} />
        } else if (ticket.isPrivate()) {
            cardHeaderStyle = [classes.courseHeader, classes.privateCourseHeader].join(' ')
            cardBodyTextStyle = [classes.cardBodyText, classes.privateCourseHeaderText].join(' ')
            ticketIcon = <ReactSVG src={'/assets/icons/light/private_lesson.svg'} />
        } else if (ticket.isMiddayCare()) {
            cardHeaderStyle = [classes.courseHeader, classes.lunchCourseHeader].join(' ')
            cardBodyTextStyle = [classes.cardBodyText, classes.lunchCourseHeaderText].join(' ')
            ticketIcon = <ReactSVG src={'/assets/icons/light/lunch.svg'} />
        }

        return (
            <div className={classes.cardContainer} onClick={() => onClickTicket(ticket)}>
                <div className={cardHeaderStyle}>
                    <div className={classes.cardHeaderIcon}>{ticketIcon}</div>
                    <Typography className={classes.cardHeaderTitle}>{ticket.getCustomer() ? ticket.getCustomer() : t('unknown')}</Typography>
                    {levelCode && <Typography className={classes.cardLevelTitle}>{levelCode}</Typography>}
                </div>
                <div className={classes.cardBody}>
                    <Typography className={cardBodyTextStyle}>{ticket.getName()}</Typography>
                    <Divider />
                    <div className={classes.cardDate}>
                        <Typography style={{ flex: 1 }} className={classes.cardDateText} align={'left'}>
                            {moment(ticket.getStartDate()).format('dddd ll')}
                        </Typography>
                        <Typography className={classes.cardDateText} align={'right'}>
                            {moment.duration(ticket.getDuration(), 'days').humanize()}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }
}

TicketCard.propTypes = {
    ticket: PropTypes.object,
    onClickTicket: PropTypes.func,
}

const styles = theme => ({
    courseHeader: {
        display: 'flex',
        minHeight: 50,
        alignItems: 'center',
        paddingLeft: theme.sizes.padding.p1,
        paddingRight: theme.sizes.padding.p1,
    },

    groupCourseHeader: {
        backgroundColor: theme.palette.blue.main,
    },
    privateCourseHeader: {
        backgroundColor: theme.palette.whiteBlue.main,
    },
    lunchCourseHeader: {
        backgroundColor: theme.palette.orange.main,
    },

    groupCourseHeaderText: {
        color: theme.palette.blue.main,
    },
    privateCourseHeaderText: {
        color: theme.palette.whiteBlue.main,
    },
    lunchCourseHeaderText: {
        color: theme.palette.orange.main,
    },

    cardContainer: {
        borderRadius: 12,
        overflow: 'hidden',
        boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
    },
    cardHeader: {
        display: 'flex',
        minHeight: 50,
        alignItems: 'center',
        paddingLeft: theme.sizes.padding.p1,
        paddingRight: theme.sizes.padding.p1,
    },

    cardHeaderIcon: {
        paddingTop: theme.sizes.padding.p1,
    },

    cardHeaderTitle: {
        paddingLeft: theme.sizes.padding.p1,
        flex: 1,
        color: theme.palette.white.main,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 700,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'pre',
    },
    cardLevelTitle: {
        paddingLeft: theme.sizes.padding.p1,
        color: theme.palette.white.main,
        fontSize: theme.typography.body2.fontSize,
    },

    cardBody: {
        backgroundColor: theme.palette.lightDark.main,
        paddingLeft: theme.sizes.padding.p1,
        paddingRight: theme.sizes.padding.p1,
    },

    cardBodyText: {
        fontSize: theme.typography.body2.fontSize,
        borderBottom: 1,
        fontWeight: 700,
        paddingTop: theme.sizes.padding.p1,
        paddingBottom: theme.sizes.padding.p1,
    },

    cardDate: {
        display: 'flex',
        paddingTop: theme.sizes.padding.p1,
        paddingBottom: theme.sizes.padding.p1,
    },

    cardDateText: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.gray.main,
    },
})

export default withTranslation()(withStyles(styles)(TicketCard))
