import { createMuiTheme } from '@material-ui/core/styles'

const primaryColor = '#D30019'

// A custom theme for this app, see https://material-ui.com/customization/default-theme/ to override theme

const theme = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '1rem',
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: '1rem',
            },
        },
        MuiInput: {
            formControl: {
                fontSize: '1rem',
            },
        },
    },
    palette: {
        primary: {
            main: primaryColor,
        },
        white: {
            main: '#fff',
        },
        secondary: {
            main: '#929191',
        },
        blue: {
            main: '#0094FA',
        },
        whiteBlue: {
            main: '#00B7FA',
        },
        orange: {
            main: '#FAB700',
        },
        lightDark: {
            main: '#F5F5F5',
        },
        gray: {
            main: '#929191',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        h1: {
            fontSize: '2.25rem',
            fontWeight: 500,
        },
        body1: {
            fontSize: '1.375rem',
        },
        body2: {
            fontSize: '1rem',
        },
        body3: {
            fontSize: '0.875rem',
        },
    },
    sizes: {
        margin: {
            m1: '0.45rem',
            m2: '0.75rem',
            m3: '1.25rem',
            m4: '2rem',
            m5: '3.125rem',
            m6: '3.75rem',
        },
        padding: {
            p1: '0.45rem',
            p2: '0.75rem',
            p3: '1.25rem',
            p4: '2rem',
            p5: '3.125rem',
            p6: '3.75rem',
        },
    },
})

export const DefaultStyles = {
    AbsoluteFill: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    CenterVertical: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    headerTitle: {
        marginBottom: theme.sizes.margin.m3,
        textTransform: 'uppercase',
    },
}

export default theme
