//import i18n from 'i18next'

const ERROR = {
    UNKNOWN: 'Unknown',
    SERVER_ERROR: 'ServerError',
    UNAUTHORIZED: 'Unauthorized',
    BAD_REQUEST: 'BadRequest',
    NOT_FOUND: 'NotFound',
    INVALID_PARAMETER: 'InvalidParameter',
}

class ApiError extends Error {
    constructor(message) {
        super(message.name || ERROR.UNKNOWN)
        this.name = 'ApiError'
        this.originError = message || { detail: ERROR.UNKNOWN, name: ERROR.UNKNOWN }
    }

    /**
     * get error type
     * @returns {ERROR}
     */
    getErrorType() {
        return this.originError.name
    }

    getMessage() {
        return this.getErrorType()
    }
}

function getNotFoundError() {
    return new ApiError({ detail: 'not found', name: ERROR.NOT_FOUND })
}

function getBadRequestError() {
    return new ApiError({ detail: 'bad request', name: ERROR.BAD_REQUEST })
}

function getUnauthorizedError() {
    return new ApiError({ detail: 'unauthorized', name: ERROR.UNAUTHORIZED })
}

function getServerError() {
    return new ApiError({ detail: 'server error', name: ERROR.SERVER_ERROR })
}

export { ERROR, ApiError, getNotFoundError, getBadRequestError, getUnauthorizedError, getServerError }
