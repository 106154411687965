import { isoStringToDate } from '../../ApiHelper'

export default class TicketLevel {
    /**
     * @param {Object<String,String,String,Number,String,String,String,String,String,String,String,String,String, Number}
     */

    constructor({ Name, Description, CompletionDate, Rating, Type, Language, LanguageId, Category, LevelCode, LevelInfo, State, TicketId, Id, Sorting }) {
        this.name = Name
        this.description = Description
        this.completionDate = isoStringToDate(CompletionDate)
        this.rating = Rating
        this.type = Type
        this.language = Language
        this.languageId = LanguageId
        this.category = Category
        this.levelCode = LevelCode
        this.levelInfo = LevelInfo
        this.state = State
        this.ticketId = TicketId
        this.id = Id
        this.sorting = Sorting
    }

    /**
     *  get id
     * @returns {Number}
     */
    getId() {
        return this.id
    }

    /**
     *  get name
     * @returns {String}
     */
    getName() {
        return this.name
    }

    /**
     *  get description
     * @returns {Number}
     */
    getDescription() {
        return this.description
    }

    /**
     *  get completionDate
     * @returns {String}
     */
    getCompletionDate() {
        return this.completionDate
    }

    /**
     *  get rating
     * @returns {String}
     */
    getRating() {
        return this.rating
    }

    /**
     *  get type
     * @returns {String}
     */
    getType() {
        return this.type
    }

    /**
     *  get language
     * @returns {String}
     */
    getLanguage() {
        return this.language
    }

    /**
     *  get language id
     * @returns {String}
     */
    getLanguageId() {
        return this.languageId
    }

    /**
     *  get category
     * @returns {String}
     */
    getCategory() {
        return this.category
    }

    /**
     *  get levelCode
     * @returns {String}
     */
    getLevelCode() {
        return this.levelCode
    }

    /**
     *  get level info
     * @returns {String}
     */
    getLevelInfo() {
        return this.levelInfo
    }

    /**
     * get state
     * @returns {String}
     */
    getState() {
        return this.state
    }

    /**
     *  get ticket id
     * @returns {String}
     */
    getTicketId() {
        return this.ticketId
    }

    /**
     *  get sorting
     * @returns {Number}
     */
    getSorting() {
        return this.sorting || 0
    }
}
