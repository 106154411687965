export default class UserActivation {
    constructor({ clientId, client, userName, userId, token, password = null, resetPasswordMode = false }) {
        this.userName = userName
        this.userId = userId
        this.token = token
        this.password = password
        this.resetPasswordMode = resetPasswordMode
    }

    getUserId() {
        return this.userId
    }

    getToken() {
        return this.token
    }

    getUsername() {
        return this.userName
    }

    setUsername(userName) {
        this.userName = userName
        return this
    }

    getPassword() {
        return this.password
    }

    setPassword(password) {
        this.password = password
        return this
    }

    isResetPasswordMode() {
        return this.resetPasswordMode
    }
}
