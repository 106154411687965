export function getURLParameters() {
    var params = {}
    if (window.location.search) {
        var parts = window.location.search.substring(1).split('&')

        for (var i = 0; i < parts.length; i++) {
            var nv = parts[i].split('=')
            if (!nv[0]) continue
            params[nv[0]] = nv[1] || true
        }
    }
    return params
}
