import Client from './Models/Client'

const { getBadRequestError, getInvalidParameterError } = require('../Errors')

export default class {
    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api
    }

    /**
     * Get client
     * @param {String} customerId (required)
     * @returns Promise<Client>
     */
    getClient(clientId) {
        if (!clientId) {
            return Promise.reject(getBadRequestError('clientId'))
        }
        const url = this.api.buildRequestURL('Clients/{id}', {
            '{id}': clientId,
        })
        return this.api.request('GET', url, null).then(client => {
            return new Client(client)
        })
    }
}
