import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { withRouter, NavLink } from 'react-router-dom'
import { PageContext } from './PageProvider'
import i18n from 'i18next'
import { List, ListItem, ListItemText, Drawer, Divider, Button, Link } from '@material-ui/core'

export const DRAWER_WIDTH = 260
export const MOBILE_MENU_BREAKPOINT = 'sm'

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            position: 'relative',
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        position: 'absolute',
    },
    drawerList: {
        marginBottom: theme.spacing(6),
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        overflowX: 'hidden',
    },
    drawerPaperDesktop: {
        paddingTop: theme.spacing(5),
    },
    menuMobile: {
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            display: 'none',
        },
    },
    desktopMenu: {
        display: 'none',
        [theme.breakpoints.between(MOBILE_MENU_BREAKPOINT, 'xl')]: {
            display: 'block',
        },
    },
    divider: {
        backgroundColor: 'rgba(255,255,255,0.2)',
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0,
        color: '#fff',
        border: '1px solid transparent',
        ['&.active']: {
            border: '1px solid #fff',
            borderRadius: 4,
        },
    },
    menuItemText: {
        ...theme.typography.subtitle1,
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    buttonsContainer: {
        marginBottom: theme.spacing(3),
    },
    button: {
        color: '#fff',
        marginBottom: theme.spacing(1),
    },
    containedButton: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
        boxShadow: 'none',
    },
}))

function DrawerMenu({ open, onOpen, onClose, history, location, match }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const context = useContext(PageContext)

    const drawerMenuItems = [
        {
            title: t('courses'),
            path: context.getClientLink('tickets'),
        },
        {
            title: t('profile'),
            path: context.getClientLink('profile'),
        },
        {
            title: t('settings'),
            path: context.getClientLink('settings'),
        },
        {
            title: t('password'),
            path: context.getClientLink('password'),
        },
        {
            title: t('lessons_overview'),
            path: context.getClientLink('lessons-overview'),
        },
    ]

    let navLinks = []
    let extendTicketLink = null
    let newBookingLink = null
    const client = context.client
    if (client) {
        if (client.getNavLinks()) {
            navLinks = client.getNavLinks().filter(link => link.Language.toLowerCase() === i18n.language.substr(0, 2))
        }
        if (client.getExtendTicketLinks()) {
            extendTicketLink = client.getExtendTicketLinks().find(link => link.Language.toLowerCase() === i18n.language.substr(0, 2))
        }
        if (client.getNewBookingLinks()) {
            newBookingLink = client.getNewBookingLinks().find(link => link.Language.toLowerCase() === i18n.language.substr(0, 2))
        }
    }

    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            onOpen()
        } else {
            onClose()
        }
    }

    const drawer = (
        <div className={classes.drawer} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List className={classes.drawerList}>
                {drawerMenuItems.map((item, index) => (
                    <div key={index}>
                        <ListItem key={item.path} component={NavLink} to={item.path} className={classes.menuItem} button>
                            <ListItemText primary={item.title} classes={{ primary: classes.menuItemText }} />
                        </ListItem>
                        <Divider classes={{ root: classes.divider }} />
                    </div>
                ))}
                {navLinks &&
                    navLinks.map((item, index) => (
                        <div key={'cusom-' + index}>
                            <ListItem key={item.path} component={Link} href={item.Link} target="_blank" rel="noopener" className={classes.menuItem} button>
                                <ListItemText primary={item.Text} classes={{ primary: classes.menuItemText }} />
                            </ListItem>
                            <Divider classes={{ root: classes.divider }} />
                        </div>
                    ))}
            </List>
            <div className={classes.buttonsContainer}>
                <Button
                    fullWidth
                    className={classes.button}
                    onClick={() => {
                        context.api.userService.logout()
                    }}>
                    {t('logout')}
                </Button>
                {newBookingLink && (
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.containedButton}
                        onClick={() => {
                            const win = window.open(newBookingLink.Text, '_blank')
                            win.focus()
                        }}>
                        {t('new_booking')}
                    </Button>
                )}
                {extendTicketLink && (
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.containedButton}
                        onClick={() => {
                            const win = window.open(extendTicketLink.Text, '_blank')
                            win.focus()
                        }}>
                        {t('extend_ticket')}
                    </Button>
                )}
            </div>
        </div>
    )

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
                variant="temporary"
                open={open}
                classes={{
                    root: classes.menuMobile,
                    paper: classes.drawerPaper,
                }}
                onClose={toggleDrawer(false)}
                //onOpen={toggleDrawer(true)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}>
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                open={false}
                classes={{
                    root: classes.desktopMenu,
                    paper: [classes.drawerPaper, classes.drawerPaperDesktop].join(' '),
                }}>
                {drawer}
            </Drawer>
        </nav>
    )
}

export default withRouter(DrawerMenu)
