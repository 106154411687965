export default class User {
    /**
     * @param {Object<Number,String,String,String,String,String,String,Boolean,Boolean,Boolean,Boolean,Boolean>}
     */
    constructor({
        Id,
        Username,
        FirstName,
        LastName,
        Role,
        IsCustomer,
        Client,
        CustomerId,
        IsPersistent,
        ToSendEmail,
        IsGdpr1Confirmed,
        IsGdpr2Confirmed,
        IsGdpr3Confirmed,
        IsGdpr4Confirmed,
        IsGdpr5Confirmed,
    }) {
        this.id = Id
        this.username = Username
        this.firstName = FirstName
        this.lastName = LastName
        this.role = Role
        this.isCustomer = IsCustomer
        this.customerId = CustomerId
        this.toSendEmail = ToSendEmail
        this.client = Client
        this.isPersistent = IsPersistent
        this.gdpr1 = IsGdpr1Confirmed
        this.gdpr2 = IsGdpr2Confirmed
        this.gdpr3 = IsGdpr3Confirmed
        this.gdpr4 = IsGdpr4Confirmed
        this.gdpr5 = IsGdpr5Confirmed
    }

    /**
     * build api request body
     */
    buildApiRequestBody() {
        return {
            Id: this.id,
            Username: this.username,
            FirstName: this.firstName,
            LastName: this.lastName,
            Role: this.role,
            IsCustomer: this.isCustomer,
            CustomerId: this.customerId,
            ToSendEmail: this.toSendEmail,
            Client: this.client,
            IsPersistent: this.isPersistent,
            IsGdpr1Confirmed: this.gdpr1,
            IsGdpr2Confirmed: this.gdpr2,
            IsGdpr3Confirmed: this.gdpr3,
            IsGdpr4Confirmed: this.gdpr4,
            IsGdpr5Confirmed: this.gdpr5,
        }
    }

    /**
     * Get user id
     * @returns {Number}
     */
    getId() {
        return this.id
    }

    /**
     * Get username
     * @returns {String}
     */
    getUsername() {
        return this.username
    }

    /**
     * Set username
     * @param {String} username
     * @returns {Self}
     */
    setUsername(username) {
        return (this.username = username)
        return this
    }

    /**
     * Get firstname
     * @returns {String}
     */
    getFirstName() {
        return this.firstName
    }

    /**
     * Set firstname
     * @param {String} firstName
     * @returns {Self}
     */
    setFirstName(firstName) {
        this.firstName = firstName
        return this
    }

    /**
     * Get lastname
     * @returns {String}
     */
    getLastName() {
        return this.lastName
    }

    /**
     * Set lastname
     * @param {String} lastName
     * @returns {Self}
     */
    setLastName(lastName) {
        this.lastName = lastName
        return this
    }

    /**
     * Is Gdpr1 comfirmed
     * @returns {Boolean}
     */
    isGdpr1Confirmed() {
        return this.gdpr1
    }

    /**
     * Set Gdpr1 comfirmed
     * @param {Boolean} confirmed
     * @returns {Boolean}
     */
    setGdpr1Confirmed(confirmed) {
        this.gdpr1 = confirmed
        return this
    }

    /**
     * Is customer
     * @returns {Boolean}
     */
    isCustomer() {
        return this.isCustomer
    }

    /**
     * Get role
     * @returns {String}
     */
    getRole() {
        return this.role
    }

    /**
     * Get client
     * @returns {String}
     */
    getClient() {
        return this.client
    }

    /**
     * Is token persistent
     * @returns {Boolean}
     */
    isTokenPersistent() {
        return this.isPersistent
    }
}
