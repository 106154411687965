import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { DefaultStyles } from '../theme'

const styles = theme => ({
    content: { ...DefaultStyles.AbsoluteFill, ...DefaultStyles.CenterVertical },
})

const Page404 = ({ classes }) => {
    return (
        <div className={classes.content}>
            <div className={classes.contentWrapper}>Page not found (404)</div>
        </div>
    )
}

export default withStyles(styles)(Page404)
