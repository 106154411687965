import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { DefaultStyles } from '../theme'
import { PageContext } from '../Context/PageProvider'
import Header from '../Components/Header'
import Body from '../Components/Body'
import Footer from '../Components/Footer'
import { Typography, Container, Grid, Fab, Divider, CircularProgress, Tabs, Tab } from '@material-ui/core'
import ReactSVG from 'react-svg'
import TicketGroups from '../Components/Tickets/TicketGroups'
import { COURSE_TYPE } from '../Components/Tickets/TicketCard'
import { Link, Element } from 'react-scroll'
import TicketDetailDialog from '../Components/Tickets/TicketDetailDialog'
import ContactInformation from '../Components/Commons/ContactInformation'

export class Tickets extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingCurrentTickets: false,
            loadingOutdatedTickets: false,
            errorMessage: null,
            customer: null,
            currentTickets: null,
            outdatedTickets: null,
            ticketDetailDialogVisible: null,
            tabIndex: 0,
        }
    }

    componentDidMount() {
        this.loadTicket()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { tabIndex, loadingCurrentTickets, currentTickets, loadingOutdatedTickets, outdatedTickets } = this.state
        if (tabIndex === 0 && !loadingCurrentTickets && currentTickets === null) {
            this.loadTicket()
        }
        if (tabIndex === 1 && !loadingOutdatedTickets && outdatedTickets === null) {
            this.loadTicket()
        }
    }

    render() {
        const { loadingCurrentTickets, loadingOutdatedTickets, currentTickets, outdatedTickets, ticketDetailDialogVisible, tabIndex } = this.state
        const { classes, t } = this.props

        const loading = tabIndex === 0 ? loadingCurrentTickets : loadingOutdatedTickets
        const tickets = tabIndex === 0 ? currentTickets : outdatedTickets
        const groupCourse = (tickets && tickets.filter(ticket => ticket.isGroup())) || []
        const privateGroupCourse = (tickets && tickets.filter(ticket => ticket.isPrivate())) || []
        const lunchGroup = (tickets && tickets.filter(ticket => ticket.isMiddayCare())) || []

        const emptyList = groupCourse.length === 0 && privateGroupCourse.length === 0 && lunchGroup.length === 0

        return (
            <Container className={classes.container}>
                <Header />
                <Body>
                    <Grid container>
                        <Grid className={classes.headerTitle} item xs={6}>
                            <Typography className={classes.headerTitleText} variant="h1">
                                {t('courses')}
                            </Typography>
                        </Grid>
                        <Grid style={{ textAlign: 'right' }} item xs={6}>
                            {groupCourse.length > 0 && (
                                <Link to="groupCourse" spy={true} smooth={true} duration={500}>
                                    <Fab className={[classes.fabButtons, classes.groupCourseBackground].join(' ')}>
                                        <ReactSVG className={classes.svgButton} src={'/assets/icons/light/group_lesson.svg'} />
                                    </Fab>
                                </Link>
                            )}
                            {privateGroupCourse.length > 0 && (
                                <Link to="privateGroupCourse" spy={true} smooth={true} duration={500}>
                                    <Fab className={[classes.fabButtons, classes.privateCourseBackground, classes.fabGroupCenter].join(' ')}>
                                        <ReactSVG className={classes.svgButton} src={'/assets/icons/light/private_lesson.svg'} />
                                    </Fab>
                                </Link>
                            )}
                            {lunchGroup.length > 0 && (
                                <Link to="lunchGroup" spy={true} smooth={true} duration={500}>
                                    <Fab className={[classes.fabButtons, classes.lunchCourseBackground].join(' ')}>
                                        <ReactSVG className={classes.svgButton} src={'/assets/icons/light/lunch.svg'} />
                                    </Fab>
                                </Link>
                            )}
                        </Grid>
                        <Grid className={classes.headerTitle} item xs={12}>
                            <Tabs className={classes.tabs} value={tabIndex} indicatorColor="primary" textColor="primary" onChange={(event, newValue) => this.setState({ tabIndex: newValue })}>
                                <Tab disableRipple label={t('current')} />
                                <Tab disableRipple label={t('outdated')} />
                            </Tabs>
                        </Grid>
                        {loading && (
                            <Grid item xs={12} style={{ marginTop: 100 }}>
                                <Grid container justify="center">
                                    <CircularProgress size={24} />
                                </Grid>
                            </Grid>
                        )}
                        {!loading && (
                            <Fragment>
                                <Grid item md={12}>
                                    <Element name="groupCourse">
                                        {groupCourse && <TicketGroups onClickTicket={ticket => this.onClickOpenDialogTicket(ticket)} groupType={COURSE_TYPE.GROUP} tickets={groupCourse} />}
                                    </Element>
                                    <Element name="privateGroupCourse">
                                        {privateGroupCourse && (
                                            <TicketGroups onClickTicket={ticket => this.onClickOpenDialogTicket(ticket)} groupType={COURSE_TYPE.PRIVATE} tickets={privateGroupCourse} />
                                        )}
                                    </Element>
                                    <Element name="lunchGroup">
                                        {lunchGroup && <TicketGroups onClickTicket={ticket => this.onClickOpenDialogTicket(ticket)} groupType={COURSE_TYPE.LUNCH} tickets={lunchGroup} />}
                                    </Element>
                                </Grid>
                                {emptyList && (
                                    <Grid className={classes.emptyListLabel} item xs={6}>
                                        <Typography className={classes.emptyListLabelText} variant="body2">
                                            {t('no_tickets_available')}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Divider className={classes.contactDivider} />
                                    <Typography className={classes.contactInfoText}>{t('contact_intro')}</Typography>
                                </Grid>
                                <Grid className={classes.contactInformation} item xs={12}>
                                    <ContactInformation />
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                    {ticketDetailDialogVisible && <TicketDetailDialog dialogClose={() => this.onTicketDetailDialogClose()} ticket={ticketDetailDialogVisible} visible={true} />}
                </Body>
            </Container>
        )
    }

    onClickOpenDialogTicket(ticket) {
        this.setState({
            ticketDetailDialogVisible: ticket,
        })
    }

    onTicketDetailDialogClose() {
        this.setState({
            ticketDetailDialogVisible: null,
        })
    }

    async loadTicket() {
        let { customer, tabIndex } = this.state
        const loadingType = tabIndex === 0 ? 'loadingCurrentTickets' : 'loadingOutdatedTickets'
        const ticketType = tabIndex === 0 ? 'currentTickets' : 'outdatedTickets'
        this.setState({ [loadingType]: true })

        const customerId = this.context.api.userService.getCustomerIdFromCurrentUser()
        if (!customer) {
            try {
                customer = await this.context.api.customerService.getCustomer(customerId)
                this.setState({ customer })
            } catch (error) {
                console.warn("can't load customer from api, error: ", error)
            }
        }

        try {
            let tickets = null
            const familyId = customer.getFamilyId()
            if (familyId && familyId.trim() !== '') {
                tickets = await this.context.api.ticketService.getTicketsWithFamilyId(customer.getFamilyId(), ticketType === 'outdatedTickets')
            } else {
                tickets = await this.context.api.ticketService.getTicketsWithCustomerId(customerId, ticketType === 'outdatedTickets')
            }

            try {
                tickets = tickets.sort((a, b) => b.getStartDate().getTime() - a.getStartDate().getTime())
            } catch (error) {
                console.warn("can't sort tickets, error: ", error)
            }
            this.setState({ [ticketType]: tickets, [loadingType]: false })
        } catch (error) {
            console.warn("can't ticketsfrom api, error: ", error)
        }
    }
}

const styles = theme => ({
    container: { ...DefaultStyles.container },

    tabs: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
    },

    contactInfoText: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.gray.main,
        marginBottom: theme.sizes.margin.m1,
    },

    contactDivider: {
        marginTop: theme.sizes.margin.m6,
        marginBottom: theme.sizes.margin.m3,
    },
    contactInformation: {
        marginBottom: theme.sizes.margin.m6,
    },

    emptyListLabel: {
        marginTop: theme.spacing(4),
    },
    emptyListLabelText: {
        color: theme.palette.gray.main,
    },

    headerTitle: {
        display: 'flex',
        alignItems: 'center',
    },

    headerTitleText: {
        textTransform: 'uppercase',
    },

    headerTitleSubText: {
        fontWeight: 500,
    },

    fabButtons: {
        [theme.breakpoints.down('sm')]: {
            width: 35,
            height: 35,
        },
        minHeight: 'auto',
    },

    svgButton: {
        '& svg': {
            [theme.breakpoints.down('sm')]: {
                width: 25,
                height: 25,
            },
        },
        marginTop: 5,
    },

    groupCourseBackground: {
        backgroundColor: theme.palette.blue.main,
    },
    privateCourseBackground: {
        backgroundColor: theme.palette.whiteBlue.main,
    },
    lunchCourseBackground: {
        backgroundColor: theme.palette.orange.main,
    },

    fabGroupCenter: {
        marginLeft: theme.sizes.margin.m1,
        marginRight: theme.sizes.margin.m1,
    },
})

Tickets.contextType = PageContext

export default withTranslation()(withStyles(styles)(Tickets))
