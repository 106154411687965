import { isoStringToDate } from '../../ApiHelper'
import TicketDate from './TicketDate'
import Level from './Level'
import TicketSkiRace from './TicketSkiRace'
import FeedBack from './Feedback'
import i18n from 'i18next'

export default class Ticket {
    /**
     * @param {Object<Boolean,Boolean,Boolean,String,String,String}
     */

    constructor({
        IsPrivate,
        IsGroup,
        IsMiddayCare,
        StartDate,
        EndDate,
        MeetingPoint,
        Dates,
        Duration,
        ItemId,
        TicketId,
        CustomerId,
        Name,
        CustomerName,
        Levels,
        SkiRace,
        Feedbacks,
        Client,
        CreatedAt,
        UpdatedAt,
        Id,
        LevelCode,
    }) {
        this.id = Id
        this._isPrivate = IsPrivate
        this._isGroup = IsGroup
        this._isMiddayCare = IsMiddayCare
        this.startDate = isoStringToDate(StartDate)
        this.endDate = isoStringToDate(EndDate)
        this.meetingPoint = MeetingPoint
        this.dates = []
        if (Dates && Dates.length > 0) {
            Dates.map(date => {
                this.dates.push(new TicketDate(date))
            })
            this.dates.sort((a, b) => a.start.getTime() - b.start.getTime())
        }
        this.duration = Duration
        this.itemId = ItemId
        this.ticketId = TicketId
        this.customerId = CustomerId
        this.name = Name
        this.customerName = CustomerName
        this.levels = []
        if (Levels && Levels.length > 0) {
            Levels.map(level => {
                this.levels.push(new Level(level))
            })
        }
        this.levelCode = LevelCode
        this.skiRace = null
        if (SkiRace) {
            this.skiRace = new TicketSkiRace(SkiRace)
        }
        this.feedbacks = []
        if (Feedbacks && Feedbacks.length > 0) {
            Feedbacks.map(feedback => {
                this.feedbacks.push(new FeedBack(feedback))
            })
        }
        this.client = Client
        this.createdAt = isoStringToDate(CreatedAt)
        this.updatedAt = isoStringToDate(UpdatedAt)
    }
    getId() {
        return this.id
    }

    /**
     *  is private
     * @returns {Boolean}
     */
    isPrivate() {
        return this._isPrivate
    }

    /**
     *  is group
     * @returns {Boolean}
     */
    isGroup() {
        return this._isGroup
    }

    /**
     *  is midday care
     * @returns {Boolean}
     */
    isMiddayCare() {
        return this._isMiddayCare
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getStartDate() {
        return this.startDate
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getEndDate() {
        return this.endDate
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getMeetingPoint() {
        return this.meetingPoint
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getDates() {
        return this.dates
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getDuration() {
        return this.duration
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getItemId() {
        return this.itemId
    }
    /**
     *  is midday care
     * @returns {Boolean}
     */
    getTicketId() {
        return this.ticketId
    }
    /**
     *  get customer id
     * @returns {Boolean}
     */
    getCustomerId() {
        return this.customerId
    }
    /**
     *  get name
     * @returns {Boolean}
     */
    getName() {
        return this.name
    }
    /**
     *  get customer
     * @returns {Boolean}
     */
    getCustomer() {
        return this.customerName
    }
    /**
     *  get level code
     * @returns {String}
     */
    getLevelCode() {
        return this.levelCode
    }
    /**
     *  get levels
     * @returns {Boolean}
     */
    getLevels() {
        return this.levels.filter(level => level.getLanguage().toLowerCase() === i18n.language.substr(0, 2))
    }
    /**
     *  get skirace
     * @returns {Boolean}
     */
    getSkiRace() {
        return this.skiRace
    }
    /**
     *  get feedback
     * @returns {Boolean}
     */
    getFeedbacks() {
        return this.feedbacks.filter(feedback => feedback.getLanguage().toLowerCase() === i18n.language.substr(0, 2))
    }
    /**
     *  get all feedback
     * @returns {Boolean}
     */
    getAllEnabledFeedbacks() {
        return this.feedbacks.filter(feedback => feedback.getEnabled() === true)
    }
    /**
     *  get feedback
     * @returns {Boolean}
     */
    getEnabledFeedbacks() {
        return this.getFeedbacks().filter(feedback => feedback.getEnabled() === true)
    }
    /**
     *  get client
     * @returns {Boolean}
     */
    getClient() {
        return this.client
    }
    /**
     *  get crate at
     * @returns {Boolean}
     */
    getCreatedAt() {
        return this.createdAt
    }
    /**
     *  get update at
     * @returns {Boolean}
     */
    getUpdatedAt() {
        return this.updatedAt
    }
}
