import { isoStringToDate } from '../../ApiHelper'

export default class Level {

    /**
     * @param {Object<String,Number,String,String,String,String,String,String,String,String,Number,Number}
     */

	constructor({ Type, Rating, Name, Description, CompletionDate, Language, LevelCode, Category, State, LevelInfo, Id, Sorting }) {
		this.type = Type
		this.rating = Rating
		this.name = Name
		this.description = Description
		this.completionDate = isoStringToDate(CompletionDate)
		this.language = Language
		this.levelCode = LevelCode
		this.category = Category
		this.state = State
		this.levelInfo = LevelInfo
        this.id = Id
        this.sorting = Sorting
	}

	/**
	*  get type
	* @returns {String}
	*/
	getType() {
		return this.type
	}

	/**
	*  get rating
	* @returns {Number}
	*/
	getRating() {
		return this.rating
	}

	/**
	*  get description
	* @returns {String}
	*/
	getDescription() {
		return this.description
	}

	/**
	*  get completionDate
	* @returns {String}
	*/
	getCompletionDate() {
		return this.completionDate
	}

	/**
	*  get language
	* @returns {String}
	*/
	getLanguage() {
		return this.language
	}

	/**
	*  get levelCode
	* @returns {String}
	*/
	getLevelCode() {
		return this.levelCode
	}

	/**
	*  get category
	* @returns {String}
	*/
	getCategory() {
		return this.category
	}

	/**
	* get state
	* @returns {String}
	*/
	getState() {
		return this.state
	}

	/**
	*  get level info
	* @returns {String}
	*/
	getLevelInfo() {
		return this.levelInfo
	}

	/**
	*  get id
	* @returns {Number}
	*/
	getId() {
		return this.id
    }
    
    /**
	*  get sorting
	* @returns {Number}
	*/
	getSorting() {
		return this.sorting || 0
	}

}