import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({}))

function AlertDialog({ open, title, message, onSubmit, onCancel, submitButtonTitle, cancelButtonTitle }) {
    const { t } = useTranslation()

    const submitTitle = submitButtonTitle || t('ok')
    const cancelTitle = cancelButtonTitle || t('cancel')

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant={'body2'}>{message}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {onCancel && (
                    <Button onClick={onCancel} color="primary">
                        {cancelTitle}
                    </Button>
                )}
                <Button onClick={onSubmit} color="primary" variant="contained">
                    {submitTitle}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog
