import React, { PureComponent } from 'react'
import { Button, Container, Grid, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../Context/PageProvider'
import { MOBILE_MENU_BREAKPOINT } from '../Context/DrawerMenu'
import MenuIcon from '@material-ui/icons/Menu'
import i18n from 'i18next'

class Header extends PureComponent {
    render() {
        const { classes, t } = this.props
        const currentUser = this.context.api.userService.getCurrentUser()

        const client = this.context.client
        let logo = null
        if (client) {
            logo = client.getLogoUrl()
        }

        return (
            <Container>
                <Grid container className={classes.headerContainer}>
                    <Grid item xs={4} style={{ display: 'flex' }}>
                        {currentUser && (
                            <IconButton color="primary" aria-label="open drawer" edge="start" onClick={() => this.context.openDrawerMenu()} className={classes.menuButton}>
                                <MenuIcon />
                            </IconButton>
                        )}
                        {logo && <img src={logo} className={classes.logo} alt="Logo" />}
                    </Grid>
                    <Grid item xs={8} className={classes.languageContainerDesktop} align={'right'}>
                        <Button size="medium" onClick={() => i18n.changeLanguage('en')} variant={i18n.language.substr(0, 2) === 'en' ? 'outlined' : 'text'} href="#" className={classes.Button}>
                            {t('english')}
                        </Button>
                        <Button size="medium" onClick={() => i18n.changeLanguage('de')} variant={i18n.language.substr(0, 2) === 'de' ? 'outlined' : 'text'} href="#" className={classes.Button}>
                            {t('german')}
                        </Button>
                    </Grid>
                    <Grid item xs={8} className={classes.languageContainerMobile} align={'right'}>
                        <Button size="small" onClick={() => i18n.changeLanguage('en')} variant={i18n.language.substr(0, 2) === 'en' ? 'outlined' : 'text'} href="#" className={classes.Button}>
                            {t('en')}
                        </Button>
                        <Button size="small" onClick={() => i18n.changeLanguage('de')} variant={i18n.language.substr(0, 2) === 'de' ? 'outlined' : 'text'} href="#" className={classes.Button}>
                            {t('de')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

Header.contextType = PageContext

const styles = theme => ({
    headerContainer: {
        marginTop: '0.625rem',
        marginBottom: '0.625rem',
    },

    logo: {
        width: 'auto',
        height: 40,
    },

    languageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    languageContainerDesktop: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    languageContainerMobile: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    languageButton: {
        marginLeft: theme.sizes.margin.m3,
        minWidth: 'auto',
    },

    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            display: 'none',
        },
    },
})

export default withTranslation()(withStyles(styles)(Header))
