import { isoStringToDate } from '../../ApiHelper'

export default class TicketSkiRace {

    /**
     * @param {Object<String,String,String,String,String,String,String,String,Number,Number,Number}
     */

	constructor({ StartDate, StartPoint, PrizePoint, PrizeDate, Time1, Time2, PenaltyTime, TotalTime, Rank, RankGroup, Id }) {
		this.startDate = isoStringToDate(StartDate)
		this.startPoint = StartPoint
		this.prizePoint = PrizePoint
		this.prizeDate = isoStringToDate(PrizeDate)
		this.time1 = Time1
		this.time2 = Time2
		this.penaltyTime = PenaltyTime
		this.totalTime = TotalTime
		this.rank = Rank
		this.rankGroup = RankGroup
		this.id = Id
	}

	/**
	*  is startDate
	* @returns {String}
	*/
	getStartDate() {
		return this.startDate
	}
	/**
	*  is startPoint
	* @returns {String}
	*/
	getStartPoint() {
		return this.startPoint
	}
	/**
	*  is prizePoint
	* @returns {String}
	*/
	getPrizePoint() {
		return this.prizePoint
	}
	/**
	*  is prizeDate
	* @returns {String}
	*/
	getPrizeDate() {
		return this.prizeDate
	}
	/**
	*  is time1
	* @returns {String}
	*/
	getTime1() {
		return this.time1
	}
	/**
	*  is time1
	* @returns {String}
	*/
	getTime2() {
		return this.time2
	}
	/**
	*  is type
	* @returns {String}
	*/
	getPenaltyTime() {
		return this.penaltyTime
	}
	/**
	*  is totalTime
	* @returns {String}
	*/
	getTotalTime() {
		return this.totalTime
	}
	/**
	*  is rank
	* @returns {Number}
	*/
	getRank() {
		return this.rank
	}

	/**
	*  is rankGroup
	* @returns {Number}
	*/
	getRankGroup() {
		return this.rankGroup
	}
	/**
	*  is id
	* @returns {Number}
	*/
	getId() {
		return this.id
	}

}