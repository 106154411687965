import React, { PureComponent, Fragment } from 'react'
import { Button, TextField, Typography, Dialog, DialogContent, DialogActions, IconButton, Divider, List, ListItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import moment from 'moment'
import { Rating } from '@material-ui/lab'
import * as Scroll from 'react-scroll'
import { Element } from 'react-scroll'

var Barcode = require('react-barcode')

class TicketDetailDialog extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            ticket: props.ticket,
            values: {},
        }
    }

    async componentDidMount() {
        const { ticket } = this.props
        // load ticket from webservice, the ticket object from props has no levels and feedbacks
        try {
            const loadedTicket = await this.context.api.ticketService.getTicket(ticket.getId())
            this.setState({ ticket: loadedTicket })
        } catch (error) {
            console.warn("can't load ticket from api, error: ", error)
        }
    }

    async handleSubmit() {
        const { values } = this.state
        const { ticket } = this.props
        this.setState({ loading: true })
        const loadedTicket = await this.context.api.ticketService.updateProps(ticket.getId(), values)
        this.setState({ loading: false, values: {}, ticket: loadedTicket })
    }

    getDialogProps() {
        const { classes, t } = this.props
        const { ticket } = this.state
        let dialogHeaderClassName
        let dialogSubTitleTextClassName
        let groupIcon
        let groupHeaderText
        let groupLabelClassName
        let dialogSubHeaderClassName

        if (ticket.isGroup()) {
            dialogHeaderClassName = [classes.dialogHeader, classes.groupCourseHeader].join(' ')
            dialogSubTitleTextClassName = [classes.dialogSubTitleText, classes.groupCourseHeaderText].join(' ')
            groupHeaderText = t('group_courses')
            groupLabelClassName = [classes.groupTitle, classes.groupCourseHeader].join(' ')
            groupIcon = <ReactSVG src={'/assets/icons/light/group_lesson.svg'} />
            dialogSubHeaderClassName = [classes.groupCourseHeader]
        } else if (ticket.isPrivate()) {
            dialogHeaderClassName = [classes.dialogHeader, classes.privateCourseHeader].join(' ')
            dialogSubTitleTextClassName = [classes.dialogSubTitleText, classes.privateCourseHeaderText].join(' ')
            groupHeaderText = t('private_courses')
            groupLabelClassName = [classes.groupTitle, classes.privateCourseHeader].join(' ')
            groupIcon = <ReactSVG src={'/assets/icons/light/private_lesson.svg'} />
            dialogSubHeaderClassName = [classes.privateCourseHeader]
        } else if (ticket.isMiddayCare()) {
            dialogHeaderClassName = [classes.dialogHeader, classes.lunchCourseHeader].join(' ')
            dialogSubTitleTextClassName = [classes.dialogSubTitleText, classes.lunchCourseHeaderText].join(' ')
            groupHeaderText = t('midday_care')
            groupLabelClassName = [classes.groupTitle, classes.lunchCourseHeader].join(' ')
            groupIcon = <ReactSVG src={'/assets/icons/light/lunch.svg'} />
            dialogSubHeaderClassName = [classes.lunchCourseHeader]
        }

        return { dialogHeaderClassName, dialogSubTitleTextClassName, groupIcon, groupHeaderText, groupLabelClassName, dialogSubHeaderClassName }
    }

    render() {
        const { classes, t, visible, dialogClose } = this.props
        const { loading, values, ticket } = this.state
        if (!ticket) return null

        const { dialogHeaderClassName, groupIcon } = this.getDialogProps()

        return (
            <Dialog
                PaperProps={{
                    classes: {
                        root: classes.dialogContainer,
                    },
                }}
                open={visible}>
                <div className={dialogHeaderClassName}>
                    <div className={classes.modalHeaderTitle}>
                        {groupIcon}
                        <Typography className={classes.modalHeaderTitleText}>{ticket.getCustomer() ? ticket.getCustomer() : t('unknown')}</Typography>
                    </div>
                    <IconButton className={classes.closeButton} onClick={dialogClose}>
                        <ReactSVG className={classes.closeIcon} src={'/assets/icons/light/close.svg'} />
                    </IconButton>
                </div>
                <DialogContent className={classes.dialogContent} id="ticket-dialog-paper" dividers>
                    {this.renderTicketBody()}
                    {!ticket.isMiddayCare() && this.renderSkiRace()}
                    {!ticket.isMiddayCare() && this.renderSkills()}
                    <Element name="feedbacks">{!ticket.isMiddayCare() && this.renderFeedbacks()}</Element>
                </DialogContent>
                {values && Object.keys(values).length > 0 && (
                    <DialogActions>
                        <Button disabled={loading} fullWidth variant="contained" type="submit" color="primary" onClick={() => this.handleSubmit()}>
                            {t('submit')}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        )
    }

    isPastDate(date) {
        moment.duration(moment(date).diff(moment())).asHours()
    }
    renderTicketBody() {
        const { classes, t } = this.props
        const { ticket } = this.state

        const feedbacks = ticket.getEnabledFeedbacks()
        const hasFeedbacks = feedbacks && feedbacks.length > 0

        const ticketNumber = ticket.getTicketId() && ticket.getTicketId().toString().trim() !== '' ? ticket.getTicketId().toString() : null

        const { dialogSubTitleTextClassName, groupHeaderText, groupLabelClassName } = this.getDialogProps()
        return (
            <div>
                <div className={classes.dialogContentTitle}>
                    <Typography className={dialogSubTitleTextClassName} style={{ flex: 1 }}>
                        {ticket.getName()}
                    </Typography>
                    {hasFeedbacks && (
                        <Button variant="outlined" color="primary" onClick={() => Scroll.scroller.scrollTo('feedbacks', { containerId: 'ticket-dialog-paper', smooth: true })}>
                            {t('feedback')}
                        </Button>
                    )}
                </div>
                <Divider />
                <div className={classes.dialogContentBody}>
                    <div className={classes.dialogSubTitle}>
                        <Typography className={groupLabelClassName} variant="h6">
                            {groupHeaderText}
                        </Typography>
                        <Typography className={dialogSubTitleTextClassName}>{moment.duration(ticket.getDuration(), 'days').humanize()}</Typography>
                    </div>
                    <div className={classes.qrCode}>
                        {/* <QRCode value={ticket.getTicketId().toString()} />
                        <Typography className={classes.qrCodeText} variant="body2">{ticket.getTicketId()}</Typography> */}
                        {ticketNumber && <Barcode format="CODE39" value={ticket.getTicketId().toString()} />}
                    </div>
                    {/**this.isPastDate(ticket.getStartDate()) */}
                    <div className={classes.dialogSubTitle}>
                        <Typography className={classes.grayText}>{moment(ticket.getStartDate()).format('dddd ll')}</Typography>
                        <Typography className={classes.grayText}>{moment(ticket.getStartDate()).fromNow()}</Typography>
                    </div>
                </div>

                {ticket.getMeetingPoint() && ticket.getMeetingPoint().trim() !== '' && (
                    <div className={classes.meetingPoint}>
                        <ReactSVG className={classes.svgButton} src={'/assets/icons/dark/location.svg'} />
                        <Typography className={classes.grayText}>
                            {t('meeting_point')} <strong>{ticket.getMeetingPoint()} </strong>{' '}
                        </Typography>
                    </div>
                )}

                {ticket.getDates().map((date, i) => {
                    if (!date.getStart()) return null
                    if (!date.getMeetingTime() && !date.getName()) {
                        return (
                            <div key={'ticket-' + i}>
                                <Divider />
                                <div className={classes.dialogContentBody}>
                                    <div className={classes.expansionPanel}>
                                        <Typography
                                            className={[classes.dialogSubTitleTextLargeSize, moment(date.getStart()).isAfter(new Date()) ? dialogSubTitleTextClassName : ''].join(' ')}
                                            variant={'body1'}>
                                            {moment(date.getStart()).format('ddd')}, {moment(date.getStart()).format('DD')}.{moment(date.getStart()).format('MM')}.{' '}
                                            {moment(date.getStart()).format('HH:mm')} - {moment(date.getEnd()).format('HH:mm')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={'ticket-' + i}>
                                <Divider />
                                <div className={classes.dialogContentBody}>
                                    <div className={classes.expansionPanel}>
                                        <Typography
                                            className={[classes.dialogSubTitleTextLargeSize, moment(date.getStart()).isAfter(new Date()) ? dialogSubTitleTextClassName : ''].join(' ')}
                                            variant={'body1'}>
                                            {moment(date.getStart()).format('ddd')}, {moment(date.getStart()).format('DD')}.{moment(date.getStart()).format('MM')}.{' '}
                                            {moment(date.getStart()).format('HH:mm')} - {moment(date.getEnd()).format('HH:mm')}
                                        </Typography>

                                        {date.getMeetingTime() && (
                                            <div className={classes.expansionPanelDetailRow}>
                                                <ReactSVG className={classes.svgButton} src={'/assets/icons/dark/time.svg'} />
                                                <Typography className={classes.grayText}>
                                                    {t('meeting_time')} <strong>{date.getMeetingTime()} </strong>{' '}
                                                </Typography>
                                            </div>
                                        )}
                                        {date.getName() && (
                                            <div className={classes.expansionPanelDetailRow}>
                                                <ReactSVG className={classes.svgButton} src={'/assets/icons/dark/instructor.svg'} />
                                                <Typography className={classes.grayText}>
                                                    {t('instructor')} <strong>{date.getName()}</strong>{' '}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    renderSkiRace() {
        const { classes, t } = this.props
        const { ticket } = this.state
        if (!ticket.getSkiRace()) return null

        const { dialogSubHeaderClassName } = this.getDialogProps()

        return (
            <div>
                <div className={dialogSubHeaderClassName}>
                    <Typography className={[classes.dialogSubHeaderText, classes.fontUpperCase].join(' ')}>{t('skirace')}</Typography>
                </div>
                <div className={classes.dialogContentBody}>
                    <Typography gutterBottom className={[classes.grayText, classes.fontWeightBold, classes.fontUpperCase].join(' ')}>
                        {t('start')}
                    </Typography>
                    <div className={classes.dialogSubTitle}>
                        <Typography className={classes.grayText}>{moment(ticket.getSkiRace().getStartDate()).format('dddd ll HH:mm')} </Typography>
                        <Typography className={classes.grayText}>{moment(ticket.getSkiRace().getStartDate()).fromNow()}</Typography>
                    </div>
                    <div className={classes.expansionPanelDetailRow}>
                        <ReactSVG className={classes.svgButton} src={'/assets/icons/dark/location.svg'} />
                        <Typography className={classes.grayText}>{ticket.getSkiRace().getStartPoint()}</Typography>
                    </div>
                    {ticket.getSkiRace().getRank() && (
                        <div className={classes.rankContainer}>
                            <ReactSVG src={'/assets/icons/number_background.svg'} />
                            <Typography className={classes.rankText}>{ticket.getSkiRace().getRank()}</Typography>
                            <Typography color={'secondary'} variant="body2">
                                {t('start_number')}
                            </Typography>
                        </div>
                    )}
                    <Divider style={{ marginBottom: '2rem' }} />
                    <Typography className={[classes.grayText, classes.fontWeightBold, classes.fontUpperCase].join(' ')}>{t('award_ceremony')}</Typography>
                    <div className={classes.dialogSubTitle}>
                        <Typography className={classes.grayText}>{moment(ticket.getSkiRace().getPrizeDate()).format('dddd ll HH:mm')} </Typography>
                        <Typography className={classes.grayText}>{moment(ticket.getSkiRace().getPrizeDate()).fromNow()}</Typography>
                    </div>
                    <div className={classes.expansionPanelDetailRow}>
                        <ReactSVG className={classes.svgButton} src={'/assets/icons/dark/location.svg'} />
                        <Typography className={classes.grayText}>{ticket.getSkiRace().getPrizePoint()}</Typography>
                    </div>
                    <div className={classes.rankContainer}>
                        <ReactSVG src={'/assets/icons/stopwatch.svg'} />
                        {
                            <Typography variant="h6" className={classes.skiRaceListText}>
                                {ticket.skiRace.getTotalTime() ? ticket.skiRace.getTotalTime().slice(0, -4) : '-'}
                            </Typography>
                        }
                    </div>
                    {(ticket.skiRace.getTime1() || ticket.skiRace.getTime2() || ticket.skiRace.getPenaltyTime()) && (
                        <List className={classes.skiRaceList}>
                            {ticket.skiRace.getTime1() && (
                                <Fragment>
                                    <ListItem>
                                        <Typography className={[classes.skiRaceListText, classes.grayText].join(' ')}>
                                            <strong>{ticket.skiRace.getTime1().slice(0, -4)}</strong> {t('1_run')}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            )}
                            {ticket.skiRace.getTime2() && (
                                <Fragment>
                                    <ListItem>
                                        <Typography className={[classes.skiRaceListText, classes.grayText].join(' ')}>
                                            <strong>{ticket.skiRace.getTime2().slice(0, -4)}</strong> {t('2_run')}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            )}
                            {ticket.skiRace.getPenaltyTime() && (
                                <ListItem>
                                    <Typography className={[classes.skiRaceListText, classes.grayText].join(' ')}>
                                        <strong>+ {ticket.skiRace.getPenaltyTime().slice(0, -4)}</strong> {t('penalty')}
                                    </Typography>
                                </ListItem>
                            )}
                        </List>
                    )}
                    {(ticket.skiRace.getRank() || ticket.skiRace.getRankGroup()) && (
                        <div className={classes.rankContainer}>
                            {ticket.skiRace.getRank() && (
                                <Fragment>
                                    <ReactSVG src={'/assets/icons/medal.svg'} />
                                    <Typography className={classes.fontWeightBold}>{ticket.skiRace.getRank()}</Typography>
                                    <Typography color={'secondary'} variant={'body2'}>
                                        {t('all_groups')}
                                    </Typography>
                                </Fragment>
                            )}
                            {
                                <Fragment>
                                    <Divider className={classes.rankDivider} />
                                    <Typography className={classes.fontWeightBold}>{ticket.skiRace.getRankGroup()}</Typography>
                                    <Typography color={'secondary'} variant={'body2'}>
                                        {t('group_ranking')}
                                    </Typography>
                                </Fragment>
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderSkills() {
        const { classes, t } = this.props
        const { ticket } = this.state

        let levels = ticket.getLevels()

        if (!levels || levels.length === 0) return null
        levels = levels.sort((a, b) => a.getSorting() - b.getSorting())
        const { groupLabelClassName, dialogSubTitleTextClassName, dialogSubHeaderClassName } = this.getDialogProps()

        return (
            <div>
                <div className={dialogSubHeaderClassName}>
                    <Typography className={[classes.dialogSubHeaderText, classes.fontUpperCase].join(' ')}>{t('skills')}</Typography>
                </div>

                {levels.map((level, i) => {
                    let smileyLeft = level.rating === 1 ? <ReactSVG src={'/assets/icons/simley_good_active.svg'} /> : <ReactSVG src={'/assets/icons/simley_good.svg'} />
                    let smileyRight = level.rating === 2 ? <ReactSVG src={'/assets/icons/simley_bad_active.svg'} /> : <ReactSVG src={'/assets/icons/simley_bad.svg'} />

                    return (
                        <div key={i}>
                            <div className={classes.dialogContentBody}>
                                {level.getCompletionDate() && <Typography className={classes.grayText}>{moment(level.getCompletionDate()).format('ddd')}</Typography>}
                                <div className={classes.dialogSubTitle}>
                                    <Typography className={dialogSubTitleTextClassName}>{level.category}</Typography>
                                    <Typography className={groupLabelClassName}>{level.name + ' / ' + level.levelCode}</Typography>
                                </div>
                                {level.description && <Typography variant={'body2'}>{level.description}</Typography>}
                                {level.getLevelInfo() && <Typography className={classes.grayText}>{level.getLevelInfo()}</Typography>}
                                {level.getType() && level.getType().toLowerCase() === 'smiley' && (
                                    <div className={classes.rating}>
                                        <IconButton className={classes.hoverTransparentBackground} disabled>
                                            {smileyLeft}
                                        </IconButton>
                                        <IconButton className={classes.hoverTransparentBackground} disabled>
                                            {smileyRight}
                                        </IconButton>
                                    </div>
                                )}
                                {level.getType() && level.getType().toLowerCase() === 'stars' && (
                                    <div className={classes.rating}>
                                        <div style={{ flex: 1 }}>
                                            <Rating value={level.rating} size="large" readOnly name={'rating'} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Divider />
                        </div>
                    )
                })}
            </div>
        )
    }

    handleFeedbackValue(feedbackId, newValue) {
        const { values } = this.state
        const newValues = { ...values }
        let feedbackValue = newValues[feedbackId] || {}
        feedbackValue.rating = newValue
        newValues[feedbackId] = feedbackValue
        const otherLanguageIdsFromFeedback = this.getOtherLanguageIdsFromFeedback(feedbackId)
        otherLanguageIdsFromFeedback.map(id => {
            newValues[id] = feedbackValue
        })
        this.setState({ values: newValues })
    }

    handleCommentValue(feedbackId, newValue) {
        const { values } = this.state
        const newValues = { ...values }
        let feedbackValue = values[feedbackId] || {}
        const otherLanguageIdsFromFeedback = this.getOtherLanguageIdsFromFeedback(feedbackId)
        if (newValue && newValue.trim() !== '') {
            feedbackValue.comment = newValue
            newValues[feedbackId] = feedbackValue
            otherLanguageIdsFromFeedback.map(id => {
                newValues[id] = feedbackValue
            })
        } else if (feedbackValue.comment) {
            delete feedbackValue.comment
            if (Object.keys(feedbackValue).length === 0) {
                delete newValues[feedbackId]
                otherLanguageIdsFromFeedback.map(id => {
                    delete newValues[id]
                })
            }
        }
        this.setState({ values: newValues })
    }

    getOtherLanguageIdsFromFeedback(feedbackId) {
        const { ticket } = this.state
        const feedbacks = ticket.getAllEnabledFeedbacks()
        const currentFeedback = feedbacks.find(feedback => feedback.getOriginalTicketFeedbackId() === feedbackId)
        if (currentFeedback) {
            console.log('currentFeedback', currentFeedback, feedbacks)
            const otherFeedbacks = feedbacks.filter(
                feedback => feedback.getOriginalTicketFeedbackId() !== feedbackId && feedback.getOriginalTicketFeedbackId() === currentFeedback.getOriginalTicketFeedbackId(),
            )
            return otherFeedbacks.map(feedback => feedback.getOriginalTicketFeedbackId())
        }
        return []
    }

    renderFeedbacks() {
        const { classes, t } = this.props
        const { loading, values, ticket } = this.state

        let feedbacks = ticket.getEnabledFeedbacks()
        if (!feedbacks || feedbacks.length == 0) return null
        const { dialogHeaderClassName, dialogSubTitleTextClassName, groupIcon, groupHeaderText, groupLabelClassName, dialogSubHeaderClassName } = this.getDialogProps()

        return (
            <div>
                <div className={classes.feedbackHeader}>
                    <Typography className={classes.dialogSubHeaderText}>{t('feedback')}</Typography>
                </div>
                {feedbacks.map((feedback, i) => {
                    const feedbackValue = values[feedback.getOriginalTicketFeedbackId()]
                    let smileyLeft = feedbackValue && feedbackValue.rating === 1 ? <ReactSVG src={'/assets/icons/simley_good_active.svg'} /> : <ReactSVG src={'/assets/icons/simley_good.svg'} />
                    let smileyRight = feedbackValue && feedbackValue.rating === 2 ? <ReactSVG src={'/assets/icons/simley_bad_active.svg'} /> : <ReactSVG src={'/assets/icons/simley_bad.svg'} />

                    return (
                        <div key={'feedback-' + feedback.getOriginalTicketFeedbackId()}>
                            <div className={classes.dialogContentBody}>
                                <Typography gutterBottom color={'primary'} className={classes.fontWeightBold} variant={'body2'}>
                                    {feedback.getDescription()}
                                </Typography>
                                <Typography variant={'body2'}>{feedback.name}</Typography>
                                <Typography gutterBottom color={'secondary'} variant={'body2'}>
                                    {' '}
                                    {feedback.additionalInformation}
                                </Typography>

                                {feedback.getType() && feedback.getType().toLowerCase() === 'stars' && (
                                    <div className={classes.rating}>
                                        <div style={{ flex: 1 }}>
                                            <Rating
                                                value={(feedbackValue && feedbackValue.rating) || 0}
                                                size="large"
                                                name={'feedback-rating-' + feedback.getOriginalTicketFeedbackId()}
                                                onChange={(event, newValue) => {
                                                    this.handleFeedbackValue(feedback.getOriginalTicketFeedbackId(), newValue)
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {feedback.getType() && feedback.getType().toLowerCase() === 'smiley' && (
                                    <div className={classes.rating}>
                                        <IconButton
                                            disabled={loading}
                                            className={classes.hoverTransparentBackground}
                                            onClick={() => {
                                                this.handleFeedbackValue(feedback.getOriginalTicketFeedbackId(), 1)
                                            }}>
                                            {smileyLeft}
                                        </IconButton>
                                        <IconButton
                                            disabled={loading}
                                            className={classes.hoverTransparentBackground}
                                            onClick={() => {
                                                this.handleFeedbackValue(feedback.getOriginalTicketFeedbackId(), 2)
                                            }}>
                                            {smileyRight}
                                        </IconButton>
                                    </div>
                                )}
                                <TextField
                                    label={t('comment')}
                                    margin="normal"
                                    inputProps={{ maxLength: 200 }}
                                    fullWidth
                                    multiline
                                    className={classes.commentInput}
                                    onChange={event => {
                                        this.handleCommentValue(feedback.getOriginalTicketFeedbackId(), event.target.value)
                                    }}
                                />
                            </div>
                            <Divider />
                        </div>
                    )
                })}
            </div>
        )
    }
}

TicketDetailDialog.contextType = PageContext

TicketDetailDialog.propTypes = {
    visible: PropTypes.bool,
    dialogClose: PropTypes.func,
    ticket: PropTypes.object,
}

const styles = theme => ({
    svgButton: {
        '& svg': {
            width: 25,
            height: 25,
            paddingTop: 5,
            marginRight: 10,
        },
    },
    hoverTransparentBackground: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    dialogContainer: {
        maxWidth: '100%',
        width: 600,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            maxHeight: '100%',
        },
    },
    commentInput: {
        marginBottom: theme.sizes.margin.m4,
    },
    feedbackDivider: {
        marginTop: theme.sizes.margin.m3,
        marginBottom: theme.sizes.margin.m3,
    },
    closeIcon: {
        '& svg': {
            width: 32,
            height: 32,
            marginTop: -3,
        },
    },
    expandIcon: {
        '& sgv': {
            width: 20,
            height: 20,
        },
    },

    rankDivider: {
        width: 160,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.sizes.margin.m3,
        marginBottom: theme.sizes.margin.m3,
    },

    modalHeaderTitleText: {
        marginLeft: theme.sizes.margin.m2,
    },

    qrCode: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.sizes.margin.m4,
        marginBottom: theme.sizes.margin.m4,
    },

    meetingPoint: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.sizes.margin.m3,
        marginBottom: theme.sizes.margin.m2,
    },

    rating: {
        flexDirection: 'row',
        width: '100%',
        textAlign: 'center',
        marginTop: theme.sizes.margin.m3,
    },

    skiRaceList: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E5E5E5',
        borderRadius: 11,
        paddingBottom: 0,
        paddingTop: 0,
    },

    skiRaceListText: {
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
    },

    qrCodeText: {
        paddingTop: theme.sizes.padding.p2,
        paddingBottom: theme.sizes.padding.p3,
    },

    rankContainer: {
        textAlign: 'center',
        position: 'relative',
        marginTop: theme.sizes.margin.m4,
        marginBottom: theme.sizes.margin.m4,
    },

    rankText: {
        textAlign: 'center',
        fontSize: theme.typography.h1.fontSize,
        fontWeight: 700,
        top: 55,
        position: 'absolute',
        width: '100%',
    },

    closeButton: {
        height: 35,
        width: 35,
        padding: 5,
    },
    dialogContentTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: theme.sizes.padding.p2,
        paddingRight: theme.sizes.padding.p2,
        paddingTop: theme.sizes.padding.p2,
        paddingBottom: theme.sizes.padding.p2,
    },
    dialogContentBody: {
        paddingLeft: theme.sizes.padding.p3,
        paddingRight: theme.sizes.padding.p3,
        paddingTop: theme.sizes.padding.p3,
        paddingBottom: theme.sizes.padding.p3,
    },
    dialogSubHeaderText: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.white.main,
        fontWeight: 500,
        paddingLeft: theme.sizes.padding.p3,
        paddingRight: theme.sizes.padding.p3,
        paddingTop: theme.sizes.padding.p1,
        paddingBottom: theme.sizes.padding.p1,
    },
    fontUpperCase: {
        textTransform: 'uppercase',
    },
    fontWeightBold: {
        fontWeight: 500,
    },

    expansionPanel: {
        boxShadow: 'none',
        '&:before': {
            opacity: 1 + '!important',
        },
    },

    dialogContent: {
        padding: 0,
    },

    expansionPanelSummary: {
        padding: 0,
    },
    expansionPanelDetails: {
        padding: 0,
        display: 'block',
    },
    expansionPanelDetailRow: {
        display: 'flex',
        alignItems: 'center',
    },
    grayText: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.gray.main,
    },

    dialogHeader: {
        padding: theme.sizes.padding.p3,
        color: theme.palette.white.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modalHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogSubTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.sizes.margin.m2,
        marginTop: theme.sizes.margin.m2,
    },
    primaryDivider: {
        marginBottom: theme.sizes.margin.m1,
        marginTop: theme.sizes.margin.m1,
        backgroundColor: theme.palette.primary.main,
    },

    dialogSubTitleText: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
    },

    dialogSubTitleTextLargeSize: {
        fontSize: theme.typography.body1.fontSize,
    },

    feedbackHeader: {
        backgroundColor: theme.palette.primary.main,
    },

    groupCourseHeader: {
        backgroundColor: theme.palette.blue.main,
    },

    privateCourseHeader: {
        backgroundColor: theme.palette.whiteBlue.main,
    },

    lunchCourseHeader: {
        backgroundColor: theme.palette.orange.main,
    },

    groupTitle: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.white.main,
        borderRadius: 2,
        textTransform: 'uppercase',
        width: 'fit-content',
        paddingLeft: theme.sizes.padding.p1,
        paddingRight: theme.sizes.padding.p1,
    },

    groupCourseHeaderText: {
        color: theme.palette.blue.main,
    },

    privateCourseHeaderText: {
        color: theme.palette.whiteBlue.main,
    },

    lunchCourseHeaderText: {
        color: theme.palette.orange.main,
    },
})

export default withTranslation()(withStyles(styles)(TicketDetailDialog))
