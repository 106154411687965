import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'

export class Body extends PureComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const { classes, maxWidth } = this.props
        const isActivationModeEnabled = this.context.api.isActivationModeEnabled()
        return (
            <div className={classes.container} style={isActivationModeEnabled ? { flex: 1, paddingTop: 50 } : { paddingTop: 50 }}>
                <Container maxWidth={maxWidth || 'sm'} classes={{ maxWidthXs: classes.maxWidthXs }}>
                    {this.props.children}
                </Container>
            </div>
        )
    }
}

const styles = theme => ({
    container: {},
    maxWidthXs: {
        maxWidth: 350,
    },
})

Body.propTypes = {
    maxWidth: PropTypes.number,
}

Body.contextType = PageContext

export default withTranslation()(withStyles(styles)(Body))
