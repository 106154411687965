import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PageProvider from './Context/PageProvider'
import { SnackbarProvider } from 'notistack'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ProtectedRoute from './ProtectedRoute'

import Loading from './Pages/Loading'
import Page404 from './Pages/Page404'
import Index from './Pages/Index'

import UserActivate from './Pages/User/UserActivate'
import UserPassword from './Pages/User/UserPassword'
import Settings from './Pages/User/Settings'
import Profile from './Pages/User/Profile'
import Tickets from './Pages/Tickets'
import LessonsOverview from './Pages/LessonsOverview'
import Login from './Pages/User/Login'
import UserReset from './Pages/User/UserReset'
import PasswordResetRequest from './Pages/User/PasswordResetRequest'

// add action to all snackbars
const notistackRef = React.createRef()
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
}

function AppRouter() {
    return (
        <Suspense fallback={<Loading />}>
            <SnackbarProvider
                ref={notistackRef}
                maxSnack={3}
                preventDuplicate
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                action={key => (
                    <IconButton onClick={onClickDismiss(key)} disableRipple size="small" style={{ color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                )}>
                <Router>
                    <PageProvider>
                        <Switch>
                            <Route path="/:clientName/:clientId/activate" exact component={UserActivate} />
                            <Route path="/:clientName/:clientId/reset" exact component={UserReset} />
                            <Route path="/:clientName/:clientId/password" exact component={UserPassword} />
                            <Route path="/:clientName/:clientId/login" exact component={Login} />
                            <Route path="/:clientName/:clientId/reset-password" exact component={PasswordResetRequest} />
                            <ProtectedRoute path="/:clientName/:clientId/settings" exact component={Settings} />
                            <ProtectedRoute path="/:clientName/:clientId/profile" exact component={Profile} />
                            <ProtectedRoute path="/:clientName/:clientId/tickets" exact component={Tickets} />
                            <ProtectedRoute path="/:clientName/:clientId/lessons-overview" exact component={LessonsOverview} />
                            <Route path="/404" exact component={Page404} />
                            <Route path="/:clientName/:clientId" component={Index} />
                            <ProtectedRoute path="/" component={Index}>
                                {/*<Redirect to={'tickets'} />*/}
                            </ProtectedRoute>
                        </Switch>
                    </PageProvider>
                </Router>
            </SnackbarProvider>
        </Suspense>
    )
}

export default AppRouter
