import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { DefaultStyles } from '../../theme'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import { PageContext } from '../../Context/PageProvider'

import { Button, Typography, Container, Grid } from '@material-ui/core'
import Header from '../../Components/Header'
import Body from '../../Components/Body'

export class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            errorMessage: null,
            customer: null,
            salutation: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            zip: '',
            city: '',
            country: '',
        }
    }

    componentDidMount() {
        const user = this.context.api.userService.getCurrentUser()
        const customerId = this.context.api.userService.getCustomerIdFromCurrentUser()
        this.context.api.customerService.getCustomer(customerId).then(customer => {
            this.setState({
                customer,
                firstName: customer.getFirstName(),
                lastName: customer.getLastName(),
                email: customer.getEmail(),
                phone: customer.getMobileNumber(),
                address: customer.getAddress(),
                zip: customer.getZip(),
                city: customer.getCity(),
                country: customer.getCountry(),
                loading: false,
            })
        })
    }

    onClickBack() {
        const { history } = this.props
        history.push(this.context.getClientLink('settings'))
    }

    onClickSubmit() {
        const { history, t } = this.props
        const { customer, firstName, lastName, email, phone, address, zip, city, country } = this.state

        this.form.isFormValid(false).then(success => {
            if (success) {
                this.setState({ loading: true })

                const upadatedCustomer = customer.setFirstName(firstName).setLastName(lastName).setEmail(email).setMobileNumber(phone).setAddress(address).setZip(zip).setCity(city).setCountry(country)

                this.context.api.customerService
                    .updateCustomer(upadatedCustomer)
                    .then(() => {
                        const isActivationModeEnabled = this.context.api.isActivationModeEnabled()
                        if (isActivationModeEnabled) {
                            this.context.api.setActivationModeEnabled(false)
                            history.push(this.context.getClientLink('tickets'))
                        } else {
                            this.setState({ loading: false })
                            this.props.enqueueSnackbar(t('profile_data_saved'), { variant: 'default' })
                        }
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar(t('can_not_save_profile_data', { message: error.getMessage() }), { variant: 'error', style: { whiteSpace: 'pre-line' } })
                        this.setState({ loading: false })
                    })
            }
        })
    }

    render() {
        const { loading, firstName, lastName, email, phone, address, zip, city, country } = this.state
        const { classes, t } = this.props

        const isActivationModeEnabled = this.context.api.isActivationModeEnabled()
        return (
            <Container className={classes.container}>
                <Header />
                <Body>
                    <Typography className={classes.headerTitleText} color="primary" variant="h1">
                        {t('your_profile')}
                    </Typography>
                    <Typography className={classes.headerTitleSubText} gutterBottom color="secondary" variant="body2">
                        {t('your_profile_sub')}
                    </Typography>
                    <ValidatorForm ref={ref => (this.form = ref)} onSubmit={() => this.onClickSubmit()}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.formSubTitle} color="secondary" variant="body2">
                                    {t('person')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('first_name')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ firstName: event.target.value, error: null })
                                    }}
                                    name="first_name"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={firstName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('last_name')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ lastName: event.target.value, error: null })
                                    }}
                                    name="last_name"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={lastName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.formSubTitle} color="secondary" variant="body2">
                                    {t('contact_data')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('email')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ email: event.target.value, error: null })
                                    }}
                                    name="email"
                                    validators={['required', 'isEmail']}
                                    errorMessages={[t('this_field_is_required'), t('email_is_not_valid')]}
                                    value={email}
                                    fullWidth
                                />
                                <Typography className={classes.helpText} color="secondary" variant="body2">
                                    {t('profile_email_help')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('phone')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ phone: event.target.value, error: null })
                                    }}
                                    name="phone"
                                    errorMessages={[t('this_field_is_required')]}
                                    value={phone}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.formSubTitle} color="secondary" variant="body2">
                                    {t('address')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('street_and_hours_number')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ address: event.target.value, error: null })
                                    }}
                                    name="address"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={address}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('post_code')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ zip: event.target.value, error: null })
                                    }}
                                    name="post_code"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={zip}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('city')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ city: event.target.value, error: null })
                                    }}
                                    name="city"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={city}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('country')}
                                    disabled={loading}
                                    onChange={event => {
                                        this.setState({ country: event.target.value, error: null })
                                    }}
                                    name="country"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={country}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.footer}>
                                {isActivationModeEnabled && (
                                    <Button onClick={() => this.onClickBack()} color="primary">
                                        {t('back')}
                                    </Button>
                                )}
                                <Button className={classes.footerRightButton} disabled={loading} type="submit" variant="contained" color="primary">
                                    {isActivationModeEnabled ? t('continue') : t('save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Body>
            </Container>
        )
    }
}

Profile.contextType = PageContext

const styles = theme => ({
    container: { ...DefaultStyles.container },

    headerTitleText: {
        marginBottom: theme.sizes.margin.m3,
        textTransform: 'uppercase',
    },

    headerTitleSubText: {
        fontWeight: 500,
    },
    formSubTitle: {
        fontSize: theme.typography.body3.fontSize,
        marginTop: theme.spacing(4),
    },
    helpText: {
        fontSize: '0.75rem',
        marginTop: theme.sizes.margin.m1,
    },
    footer: {
        marginTop: theme.sizes.margin.m6,
        display: 'flex',
        alignItems: 'center',
    },
    footerRightButton: {
        marginLeft: 'auto',
    },
})

export default withTranslation()(withStyles(styles)(withSnackbar(Profile)))
