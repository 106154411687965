import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import moment from 'moment'
import 'moment/locale/de'

import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //lng: 'de',
        fallbackLng: ['en'],
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

// Keep the language on moment.js in sync with i18next
// by listening to the change language event.
i18n.on('languageChanged', newlocale => {
    moment.locale(newlocale)
})

export default i18n
