import { isoStringToDate } from '../../ApiHelper'

export default class FeedBack {

    /**
     * @param {Object<String,Number,String,String,String,String,Number,Boolean,Number}
     */

	constructor({ Type, Name, Description, Rating, Enabled, Id, Language, OriginalTicketFeedbackId, TicketId }) {
		this.type = Type
		this.name = Name
		this.description = Description
		this.rating = Rating
		this.enabled = Enabled
		this.id = Id
        this.language = Language
        this.originalTicketFeedbackId = OriginalTicketFeedbackId
        this.ticketId = TicketId
	}

	/**
	*  get type
	* @returns {String}
	*/
	getType() {
		return this.type
	}

	/**
	*  get name
	* @returns {String}
	*/
	getName() {
		return this.name
	}
	/**
	*  get description
	* @returns {String}
	*/
	getDescription() {
		return this.description
	}
	/**
	*  get rating
	* @returns {Number}
	*/
	getRating() {
		return this.rating
	}
	/**
	*  set rating
	* @returns {Number}
	*/
	setRating(rating) {
		this.rating = rating
		return this
	}
	/**
	*  get enabled
	* @returns {Boolean}
	*/
	getEnabled() {
		return this.enabled
	}
	/**
	*  get id
	* @returns {Number}
	*/
	getId() {
		return this.id
	}
	/**
	 * get language
	 * @returns {String}
	 */
	getLanguage() {
		return this.language
    }
    /**
	 * get original ticket feedback id
	 * @returns {String}
	 */
    getOriginalTicketFeedbackId() {
        return this.originalTicketFeedbackId
    }
    /**
	 * get original ticket feedback id
	 * @returns {String}
	 */
    getTicketId() {
        return this.ticketId
    }
}