export default class Client {
    /**
     * @param {Object<String...>}
     */
    constructor({ Name, WebDirectUrl, Logo, Title, Phone, Gdpr1, Gdpr2, Gdpr3, Gdpr4, Gdpr5, NavLinks, GdprLinks, ExtendTicket, NewBooking }) {
        this.name = Name
        this.webRedirectUrl = WebDirectUrl
        this.logo = Logo
        this.title = Title
        this.phone = Phone
        this.gdpr1 = Gdpr1
        this.gdpr2 = Gdpr2
        this.gdpr3 = Gdpr3
        this.gdpr4 = Gdpr4
        this.gdpr5 = Gdpr5
        this.navLinks = NavLinks
        this.gdprLinks = GdprLinks
        if (this.navLinks) {
            this.navLinks = this.navLinks.map(navLink => {
                if (navLink.Link.substring(0, 7) !== 'http://' && navLink.Link.substring(0, 8) !== 'https://') {
                    navLink.Link = 'http://' + navLink.Link
                }
                return navLink
            })
        }
        this.extendTicketLinks = ExtendTicket
        if (this.extendTicketLinks) {
            this.extendTicketLinks = this.extendTicketLinks.map(link => {
                if (link.Text.substring(0, 7) !== 'http://' && link.Text.substring(0, 8) !== 'https://') {
                    link.Text = 'http://' + link.Text
                }
                return link
            })
        }
        this.newBookingLinks = NewBooking
        if (this.newBookingLinks) {
            this.newBookingLinks = this.newBookingLinks.map(link => {
                if (link.Text.substring(0, 7) !== 'http://' && link.Text.substring(0, 8) !== 'https://') {
                    link.Text = 'http://' + link.Text
                }
                return link
            })
        }
    }

    /**
     * Get client name
     * @returns {String}
     */
    getName() {
        return this.name
    }

    /**
     * Get title
     * @returns {String}
     */
    getTitle() {
        return this.title
    }

    /**
     * Get phone number
     * @returns {String}
     */
    getPhoneNumber() {
        return this.phone
    }

    /**
     * Get web redirect url
     * @returns {String}
     */
    getWebRedirectUrl() {
        return this.webRedirectUrl
    }

    /**
     * Get logo url
     * @returns {String}
     */
    getLogoUrl() {
        return this.logo
    }

    /**
     * Get Gdpr1 Object
     * @returns {Object}
     */
    getGdpr1() {
        return this.gdpr1
    }

    /**
     * Get Gdpr2 Object
     * @returns {Object}
     */
    getGdpr2() {
        return this.gdpr2
    }

    /**
     * Get Gdpr3 Object
     * @returns {Object}
     */
    getGdpr3() {
        return this.gdpr3
    }

    /**
     * Get Gdpr4 Object
     * @returns {Object}
     */
    getGdpr4() {
        return this.gdpr4
    }

    /**
     * Get Gdpr5 Object
     * @returns {Object}
     */
    getGdpr5() {
        return this.gdpr5
    }

    /**
     * get language filtered gdpr fields
     * @param {*} lang
     */
    getGdprFields(lang) {
        let fields = []

        let gdprLink
        this.gdprLinks.map(link => {
            if (link.Language.toLowerCase() === lang.toLowerCase()) {
                gdprLink = link.Text
            }
        })
        const gdpr1 = this.getGdpr1()
        const gdpr1Field = gdpr1 && gdpr1.find(field => field.Language && field.Language.toLowerCase() === lang.toLowerCase())
        if (gdpr1Field && gdpr1Field.Text && gdpr1Field.Text.trim() !== '')
            fields.push({ key: 'gdpr1', isRequired: true, link: { title: 'privacy_information', href: gdprLink }, title: gdpr1Field.Text })

        const gdpr2 = this.getGdpr2()
        const gdpr2Field = gdpr2 && gdpr2.find(field => field.Language && field.Language.toLowerCase() === lang.toLowerCase())
        if (gdpr2Field && gdpr2Field.Text && gdpr2Field.Text.trim() !== '') fields.push({ key: 'gdpr2', isRequired: false, link: null, title: gdpr2Field.Text })

        const gdpr3 = this.getGdpr3()
        const gdpr3Field = gdpr3 && gdpr3.find(field => field.Language && field.Language.toLowerCase() === lang.toLowerCase())
        if (gdpr3Field && gdpr3Field.Text && gdpr3Field.Text.trim() !== '') fields.push({ key: 'gdpr3', isRequired: false, link: null, title: gdpr3Field.Text })

        const gdpr4 = this.getGdpr4()
        const gdpr4Field = gdpr4 && gdpr4.find(field => field.Language && field.Language.toLowerCase() === lang.toLowerCase())
        if (gdpr4Field && gdpr4Field.Text && gdpr4Field.Text.trim() !== '') fields.push({ key: 'gdpr4', isRequired: false, link: null, title: gdpr4Field.Text })

        const gdpr5 = this.getGdpr5()
        const gdpr5Field = gdpr5 && gdpr5.find(field => field.Language && field.Language.toLowerCase() === lang.toLowerCase())
        if (gdpr5Field && gdpr5Field.Text && gdpr5Field.Text.trim() !== '') fields.push({ key: 'gdpr5', isRequired: false, link: null, title: gdpr5Field.Text })

        return fields
    }

    /**
     * Get nav links
     * @returns {Object}
     */
    getNavLinks() {
        return this.navLinks
    }

    /**
     * Get extend ticket links
     * @returns {Object}
     */
    getExtendTicketLinks() {
        return this.extendTicketLinks
    }

    /**
     * Get new booking links
     * @returns {Object}
     */
    getNewBookingLinks() {
        return this.newBookingLinks
    }
}
