import TicketLevel from './Models/TicketLevel'

const { getBadRequestError, getInvalidParameterError } = require('../Errors')

export default class {
    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api
    }

    /**
     * get levels
     * @returns Promise<TicketLevel>
     */
    getLevels() {
        const url = this.api.buildRequestURL('Levels')
        return this.api.request('GET', url, null).then(levels => {
            return levels.map(level => new TicketLevel(level))
        })
    }
}
