import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'

export class Footer extends PureComponent {
    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <Container maxWidth={'sm'}>{this.props.children}</Container>
            </div>
        )
    }
}

const styles = theme => ({
    container: {
        marginBottom: theme.sizes.margin.m3,
        marginTop: theme.sizes.margin.m4,
    },
})

export default withTranslation()(withStyles(styles)(Footer))
