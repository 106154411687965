import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { DefaultStyles } from '../../theme'
import Loading from '../../Pages/Loading'
import { getURLParameters } from '../../Helper'
import UserActivation from '../../hgi-sa-api/UserService/Models/UserActivation'

const jwtDecode = require('jwt-decode')

const styles = theme => ({
    content: { ...DefaultStyles.AbsoluteFill },
})

class UserActivate extends PureComponent {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { history } = this.props
        const user = this.context.api.userService.getCurrentUser()
        if (user) {
            this.context.api.userService.silentLogout()
        }

        try {
            const query = getURLParameters()
            const clientId = query.clientId
            const userName = query.username
            const userId = query.UserId
            const token = query.token

            let decodedToken = null
            decodedToken = jwtDecode(token)

            this.context.api.setClient(clientId, decodedToken.client)
            this.context.api.setActivationModeEnabled(true)

            let userActivation = new UserActivation({ userName, userId, token, resetPasswordMode: false })
            history.push(this.context.getClientLink('password'), { userActivation })
        } catch (error) {
            console.warn('jwtDecode error: ', error)
        }
    }

    render() {
        const { classes } = this.props
        return <Loading />
    }
}

UserActivate.contextType = PageContext

export default withTranslation()(withStyles(styles)(UserActivate))
