/* eslint no-undef: "off"*/

/**
 * HGI SA MobileWebService API
 * documentation: https://rccloud.hgisystems.com:5023/swagger/index.html
 */
import UserService from './UserService'
import CustomerService from './CustomerService'
import ClientService from './ClientService'
import TicketService from './TicketService'
import TicketLevelService from './TicketLevelService'
import ls from 'local-storage'
const Promise = require('bluebird')
const { ApiError, getUnauthorizedError, getNotFoundError, getBadRequestError, getServerError } = require('./Errors')

const TOKEN_STORAGE_KEY = 'hgi-sa-api-token'
const CLIENT_ID_STORAGE_KEY = 'hgi-sa-api-client-id'
const CLIENT_NAME_STORAGE_KEY = 'hgi-sa-api-client'
const ACTIVATION_MODE_ENABLED_STORAGE_KEY = 'hgi-sa-api-activation-mode'

/**
 * HGI SA MobileWebService API
 *
 * @param {String} domain
 * @param {String} logger
 */
export default class Api {
    /**
     * @param {String} domain
     * @param {Boolean} debugEnabled
     */
    constructor(domain, debugEnabled = false) {
        this.domain = domain
        this.clientDomain = null
        this.debugEnabled = debugEnabled

        this.userService = new UserService(this)
        this.customerService = new CustomerService(this)
        this.clientService = new ClientService(this)
        this.ticketService = new TicketService(this)
        this.ticketLevelService = new TicketLevelService(this)

        this.client = null
    }

    /**
     * Get client id
     * @returns {String}
     */
    getClientId() {
        return ls.get(CLIENT_ID_STORAGE_KEY)
    }

    /**
     * Get client name
     * @returns {String}
     */
    getClientName() {
        return ls.get(CLIENT_NAME_STORAGE_KEY)
    }

    /**
     * Set client id
     * @param {String} clientId
     */
    setClient(clientId, clientName) {
        this.client = null
        if (!clientId) {
            ls.remove(CLIENT_ID_STORAGE_KEY)
        } else {
            ls.set(CLIENT_ID_STORAGE_KEY, clientId)
        }
        if (!clientName) {
            ls.remove(CLIENT_NAME_STORAGE_KEY)
        } else {
            ls.set(CLIENT_NAME_STORAGE_KEY, clientName)
        }
    }

    /**
     * get api token
     * @returns {String}
     */
    getToken() {
        if (this.token) return this.token
        return ls.get(TOKEN_STORAGE_KEY)
    }

    /**
     * set api token
     * @param {String} token
     */
    setToken(token) {
        if (!token) {
            ls.remove(TOKEN_STORAGE_KEY)
        } else {
            this.token = token
            ls.set(TOKEN_STORAGE_KEY, token)
        }
    }

    /**
     * is activation mode enabled
     * @returns {Boolean}
     */
    isActivationModeEnabled() {
        return ls.get(ACTIVATION_MODE_ENABLED_STORAGE_KEY) ? true : false
    }

    /**
     * set activation mode enabled
     * @param {Boolean} enabled
     */
    setActivationModeEnabled(enabled) {
        if (!enabled) {
            ls.remove(ACTIVATION_MODE_ENABLED_STORAGE_KEY)
        } else {
            ls.set(ACTIVATION_MODE_ENABLED_STORAGE_KEY, true)
        }
    }

    /**
     * Make Api request
     * @param {String} method e.g. GET
     * @param {String} url
     * @param {Object<any>} body
     * @param {Object<{key: value}>} headers
     * @param {String} customToken
     */
    async request(method, url, body, headers, customToken = null) {
        if (this.debugEnabled) {
            console.log(`request ${method} ${url}\nBody:`, body)
        }

        const token = customToken || this.getToken()
        let authorization = {}
        if (token) {
            authorization['Authorization'] = 'Bearer ' + token
        }

        const doRequest = (method, url, body, headers, rawResponse = false, withoutAuthorization = false) => {
            const requestUrl = url.replace('{clientDomain}', this.clientDomain)
            return new Promise(async (resolve, reject) => {
                fetch(requestUrl, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        ...(withoutAuthorization ? {} : authorization),
                        ...(headers || {}),
                    },
                    body: body ? (typeof body === 'string' ? body : JSON.stringify(body)) : null,
                })
                    .then(response => {
                        if (!response.ok) {
                            if (this.debugEnabled) {
                                console.log(`response ${method} ${requestUrl}\nResponse status: ${response.status}`)
                            }
                            if (response.status === 404) {
                                reject(getNotFoundError())
                                return
                            }
                            if (response.status === 401) {
                                reject(getUnauthorizedError())
                                return
                            }
                            if (response.status === 400) {
                                reject(getBadRequestError())
                                return
                            }

                            response
                                .json()
                                .then(error => {
                                    reject(new ApiError(error))
                                })
                                .catch(error => {
                                    reject(new ApiError(error))
                                })
                        } else {
                            response
                                .text()
                                .then(body => {
                                    if (this.debugEnabled) {
                                        console.log(`response ${method} ${requestUrl}:\n${body}\n\n`)
                                    }
                                    if (!body || body.trim() === '') {
                                        resolve(null)
                                        return
                                    }
                                    if (rawResponse) {
                                        resolve(body)
                                    } else {
                                        try {
                                            const json = JSON.parse(body)
                                            resolve(json)
                                        } catch (error) {
                                            console.error('Error:', error)
                                            console.error('Response body:', body)
                                            reject(error)
                                        }
                                    }
                                })
                                .catch(error => {
                                    reject(error)
                                })
                        }
                    })
                    .catch(error => {
                        console.log('api request error', error)
                        reject(getServerError())
                    })
            })
        }

        if (!this.clientDomain) {
            const clientDomainRequestUrl = `${this.domain}/api/sa/Clients/GetBaseUrl/${this.getClientName()}`
            //console.log('API - GetBaseUrl for client: ', this.getClientName() )
            try {
                const clientDomain = await doRequest('GET', clientDomainRequestUrl, null, null, true, true)
                this.clientDomain = clientDomain
                console.log('API - GetBaseUrl result: ', clientDomain)
            } catch (error) {
                console.error('API - GetBaseUrl error: ', error)
                reject(error)
                return
            }
        }

        return doRequest(method, url, body, headers)
    }

    /**
     * Build request url
     * @param {String} path
     * @param {Object} replacements
     * @param {Object} queryParameters
     *
     * @returns String request url
     */
    buildRequestURL(path, replacements, queryParameters) {
        if (replacements) {
            Object.keys(replacements).map(key => {
                path = path.replace(key, replacements[key])
            })
        }

        let queryParameterKeys = queryParameters && Object.keys(queryParameters)
        const queryParametersString = queryParameterKeys && queryParameterKeys.length > 0 ? '?' + queryParameterKeys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&') : ''
        return '{clientDomain}' + path + queryParametersString
    }
}
