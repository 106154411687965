export default class Customer {
    /**
     * @param {Object<String...>}
     */
    constructor({
        Id,
        Client,
        CustomerNr,
        Company,
        FirstName,
        LastName,
        TitlePrefix,
        TitleSuffix,
        Address,
        IsCompany,
        Taxnumber,
        Zip,
        City,
        Country,
        Phone,
        Status,
        Email,
        ImageUrl,
        Mobile,
        Fax,
        PriceId,
        UserId,
        FamilyId,
        Age,
        Level,
        ListTypes,
        Homepage,
        Language,
        Accomodation,
        SpecialRequests,
        CreatedAt,
        UpdatedAt,
        DeletedAt,
    }) {
        this.id = Id
        this.client = Client
        this.customerNr = CustomerNr
        this.company = Company
        this.firstName = FirstName
        this.lastName = LastName
        this.address = Address
        this.isCompany = IsCompany
        this.taxnumber = Taxnumber
        this.zip = Zip
        this.city = City
        this.country = Country
        this.phone = Phone
        this.status = Status
        this.email = Email
        this.imageUrl = ImageUrl
        this.mobile = Mobile
        this.fax = Fax
        this.priceId = PriceId
        this.listTypes = ListTypes
        this.familyId = FamilyId
        this.createdAt = CreatedAt
        this.updatedAt = UpdatedAt
    }

    /**
     * build api request body
     */
    buildApiRequestBody() {
        return {
            Id: this.id,
            Client: this.client,
            CustomerNr: this.customerNr,
            FirstName: this.firstName,
            LastName: this.lastName,
            Address: this.address,
            IsCompany: this.isCompany,
            Taxnumber: this.taxnumber,
            Zip: this.zip,
            City: this.city,
            Country: this.country,
            Phone: this.phone,
            Status: this.status,
            Email: this.email,
            ImageUrl: this.imageUrl,
            Mobile: this.mobile,
            Fax: this.fax,
            PriceId: this.priceId,
            ListTypes: this.listTypes,
        }
    }

    /**
     * Get customer id
     * @returns {Number}
     */
    getId() {
        return this.id
    }

    /**
     * Get firstname
     * @returns {String}
     */
    getFirstName() {
        return this.firstName
    }

    /**
     * Get lastname
     * @returns {String}
     */
    getLastName() {
        return this.lastName
    }

    /**
     * Get email
     * @returns {String}
     */
    getEmail() {
        return this.email
    }
    /**
     * Get mobile phone
     * @returns {String}
     */
    getMobileNumber() {
        return this.mobile
    }
    /**
     * Get Address
     * @returns {String}
     */
    getAddress() {
        return this.address
    }
    /**
     * Get Zip
     * @returns {String}
     */
    getZip() {
        return this.zip
    }
    /**
     * Get City
     * @returns {String}
     */
    getCity() {
        return this.city
    }
    /**
     * Get Country
     * @returns {String}
     */
    getCountry() {
        return this.country
    }
    /**
     * Set FirstName
     * @param {String} lastName
     * @returns {Self}
     */
    setFirstName(firstName) {
        this.firstName = firstName
        return this
    }
    /**
     * Set LastName
     * @param {String} lastName
     * @returns {Self}
     */
    setLastName(lastName) {
        this.lastName = lastName
        return this
    }
    /**
     * Set Email
     * @param {String} email
     * @returns {Self}
     */
    setEmail(email) {
        this.email = email
        return this
    }
    /**
     * Set mobile number
     * @param {String} phone
     * @returns {Self}
     */
    setMobileNumber(mobile) {
        this.mobile = mobile
        return this
    }
    /**
     * Set Address
     * @param {String} address
     * @returns {Self}
     */
    setAddress(address) {
        this.address = address
        return this
    }
    /**
     * Set Zip(post code)
     * @param {String} zip
     * @returns {Self}
     */
    setZip(zip) {
        this.zip = zip
        return this
    }
    /**
     * Set city
     * @param {String} city
     * @returns {Self}
     */
    setCity(city) {
        this.city = city
        return this
    }
    /**
     * Set country
     * @param {String} country
     * @returns {Self}
     */
    setCountry(country) {
        this.country = country
        return this
    }

    /**
     * get family id
     */
    getFamilyId() {
        return this.familyId
    }
}
