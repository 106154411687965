import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PageContext } from '../../Context/PageProvider'
import { Button, Typography, Container, Grid } from '@material-ui/core'
import { DefaultStyles } from '../../theme'
import Header from '../../Components/Header'
import Body from '../../Components/Body'
import { ERROR } from '../../hgi-sa-api/Errors'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            loading: false,
            errorMessages: null,
        }
    }

    componentDidMount() {
        const { history } = this.props
        const clientName = this.context.api.getClientName()
        if (clientName) {
            const user = this.context.api.userService.getCurrentUser()
            if (user) {
                history.push(this.context.getClientLink('profile'))
            }
        } else {
            console.log('invalid client name, redirect to 404 page...')
            history.push('/404')
        }
    }

    onClickSubmit() {
        const { username, password } = this.state
        const { history, t } = this.props

        this.setState({ loading: true }, () => {
            this.context.api.userService
                .authUser(username, password)
                .then(result => {
                    this.context.api.setActivationModeEnabled(false)
                    window.location.href = '/tickets'
                })
                .catch(error => this.setError(error))
        })
    }

    setError(error) {
        const { t } = this.props
        let message = t('error_unknown')
        if (error && error.originError) {
            console.log('error.originError.name', error.originError.name)
            if (error.originError.name === ERROR.NOT_FOUND || error.originError.name === ERROR.UNAUTHORIZED) {
                message = t('error_invalid_username_or_password')
            }
        }
        this.setState({ errorMessage: message, loading: false })
    }

    onResetPasswordClick() {
        const { history } = this.props
        history.push(this.context.getClientLink('reset-password'))
    }

    render() {
        const { username, password, errorMessage, loading } = this.state
        const { classes, t } = this.props

        return (
            <Container className={classes.container}>
                <Header />
                <Body maxWidth={'xs'}>
                    <ValidatorForm>
                        <Grid className={classes.formContainer} container direction="column" alignItems="stretch" justify="flex-start" spacing={3}>
                            <Grid item>
                                <Typography className={classes.headerTitle} color="primary" variant="h1">
                                    {t('login')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextValidator
                                    label={t('email')}
                                    onChange={event => {
                                        this.setState({ username: event.target.value, errorMessage: null })
                                    }}
                                    name="username"
                                    type="text"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={username}
                                    fullWidth
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item>
                                <TextValidator
                                    label={t('password')}
                                    onChange={event => {
                                        this.setState({ password: event.target.value, errorMessage: null })
                                    }}
                                    name="password"
                                    type="password"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required'), t('passwords_do_not_match')]}
                                    value={password}
                                    fullWidth
                                    className={classes.input}
                                />
                            </Grid>
                            {errorMessage && (
                                <Grid item>
                                    <Typography variant="body2" color="error" className={classes.errorMessage}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={loading}
                                    onClick={() => this.onClickSubmit()}
                                    disabled={password.trim() === '' || username.trim() === ''}
                                    variant="contained"
                                    color="primary">
                                    {t('login')}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button fullWidth onClick={() => this.onResetPasswordClick()}>
                                    {t('reset_password')}
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Body>
            </Container>
        )
    }
}

const styles = theme => ({
    clientErrorContent: { ...DefaultStyles.AbsoluteFill },
    container: { ...DefaultStyles.container },
    formContainer: {
        marginTop: theme.sizes.margin.m3,
    },
    headerTitle: { ...DefaultStyles.headerTitle },
    errorMessage: {
        marginTop: theme.spacing(1),
    },
})

Login.contextType = PageContext

export default withTranslation()(withStyles(styles)(Login))
