import Customer from './Models/Customer'

const { getBadRequestError, getInvalidParameterError } = require('../Errors')

export default class {
    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api
    }

    /**
     * Queries a specific customer for the given client.
     * @param {String} customerId (required)
     * @returns Promise<Customer>
     */
    getCustomer(customerId) {
        if (!customerId) {
            return Promise.reject(getBadRequestError('customerId'))
        }
        const url = this.api.buildRequestURL('Customers/{id}', {
            '{id}': customerId,
        })
        return this.api.request('GET', url, null).then(customer => {
            return new Customer(customer)
        })
    }

    /**
     * Updates a specific customer.
     * @param {User} user
     * @returns Promise<User>
     */
    updateCustomer(customer) {
        if (!customer) {
            return Promise.reject(getBadRequestError('customer'))
        }
        const url = this.api.buildRequestURL('Customers/{id}', {
            '{id}': customer.getId(),
        })
        return this.api.request('PUT', url, customer.buildApiRequestBody()).then(result => {
            return result
        })
    }
}
