import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Loading from './Loading'
import { PageContext } from '../Context/PageProvider'

export class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidUpdate() {
        this.init()
    }

    componentDidMount() {
        this.init()
    }

    init() {
        const { history } = this.props

        if (!this.context.ready) {
            console.log('context is not ready.')
            return
        }

        const clientName =
            this.props && this.props.match && this.props.match.params && this.props.match.params.clientName && this.props.match.params.clientName.trim() !== ''
                ? this.props.match.params.clientName.trim()
                : null
        const clientId =
            this.props && this.props.match && this.props.match.params && this.props.match.params.clientId && this.props.match.params.clientId.trim() !== ''
                ? this.props.match.params.clientId.trim()
                : null
        const user = this.context.api.userService.getCurrentUser()

        if (user) {
            if (clientName && clientId && user.getClient() !== clientName) {
                console.warn('client changed, logout current user ...')
                this.context.api.userService.logout()
            } else {
                history.push(this.context.getClientLink('tickets'))
            }
        } else {
            if (clientName && clientId) {
                this.context.api.setClient(clientId, clientName)
            }
            this.context.api.setActivationModeEnabled(false)
            history.push(this.context.getClientLink('login'))
        }
    }

    render() {
        return <Loading />
    }
}

const styles = theme => ({})

Index.contextType = PageContext

export default withTranslation()(withStyles(styles)(Index))
