import React, { PureComponent, Fragment } from 'react'
import { Container, Typography, Divider, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { DefaultStyles } from '../theme'
import { PageContext } from '../Context/PageProvider'
import i18n from 'i18next'
import Header from '../Components/Header'
import Body from '../Components/Body'
import Footer from '../Components/Footer'
import ReactSVG from 'react-svg'
import ContactInformation from '../Components/Commons/ContactInformation'

class LessonsOverview extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            language: i18n.language.substr(0, 2),
            groups: null,
        }
    }

    componentDidMount() {
        this.loadLevels()
    }

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2),
                    groups: null,
                },
                () => this.loadLevels(),
            )
        }
    }

    render() {
        const { classes, t } = this.props
        const { loading, groups } = this.state

        return (
            <Container className={classes.container}>
                <Header />
                <Body>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h1" gutterBottom>
                                {t('lessons_overview')}
                            </Typography>
                            <Typography color={'secondary'} variant="body2">
                                {t('lessons_overview_description')}
                            </Typography>
                        </Grid>
                        {loading && (
                            <Grid item xs={12} style={{ marginTop: 100 }}>
                                <Grid container justify="center">
                                    <CircularProgress size={24} />
                                </Grid>
                            </Grid>
                        )}
                        {!loading && groups && (
                            <Fragment>
                                <Grid item xs={12}>
                                    {Object.keys(groups)
                                        .sort()
                                        .map(groupKey => (
                                            <ExpansionPanel className={classes.expansionPanel}>
                                                <ExpansionPanelSummary
                                                    className={classes.expansionPanelSummary}
                                                    expandIcon={
                                                        <ReactSVG
                                                            beforeInjection={svg => {
                                                                svg.setAttribute('style', 'width: 20px; height: 20px')
                                                            }}
                                                            src={'/assets/icons/dark/expand.svg'}
                                                        />
                                                    }
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography className={classes.dialogSubTitleText}>{groupKey}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                                    {groups[groupKey].map(group => (
                                                        <div>
                                                            {group.name && group.name.trim() !== '' && <div className={classes.expansionPanelDetailRowTitle}>{group.name}</div>}
                                                            {group.items &&
                                                                group.items.map(level => (
                                                                    <div className={classes.expansionPanelDetailRow}>
                                                                        <ReactSVG
                                                                            beforeInjection={svg => {
                                                                                svg.setAttribute('style', 'width: 25px; height: 25px')
                                                                            }}
                                                                            src={'/assets/icons/primary/check.svg'}
                                                                        />
                                                                        <Typography className={classes.checkText} variant="body2" gutterBottom>
                                                                            {level.getDescription()}
                                                                        </Typography>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    ))}
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        ))}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.contactDivider} />
                                    <Typography className={classes.contactInfoText}>{t('contact_intro')}</Typography>
                                </Grid>
                                <Grid className={classes.contactInformation} item xs={12}>
                                    <ContactInformation />
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </Body>
                <Footer></Footer>
            </Container>
        )
    }

    loadLevels() {
        this.setState({ loading: true })

        this.context.api.ticketLevelService
            .getLevels()
            .then(levels => {
                levels = levels.filter(level => level.getLanguage().toLowerCase() === i18n.language.substr(0, 2))
                levels = levels.sort((a, b) => a.getSorting() - b.getSorting())
                const groups = {}
                levels.map(level => {
                    const name = level.getLevelCode().trim()
                    const category = level.getCategory().trim()
                    if (!groups[name]) groups[name] = []
                    const categoryItemIndex = groups[name].findIndex(categoryItem => categoryItem.name === category)
                    if (categoryItemIndex >= 0) {
                        groups[name][categoryItemIndex]['items'].push(level)
                    } else {
                        groups[name].push({ name: category, items: [level], sorting: level.getSorting() })
                    }
                })
                Object.keys(groups).map(name => {
                    groups[name] = groups[name].sort((a, b) => a.sorting - b.sorting)
                })
                this.setState({ groups, loading: false })
            })
            .catch(error => {
                this.setState({ loading: false })
                console.warn('loadLevels, error', error)
            })
    }
}

LessonsOverview.propTypes = {}

const styles = theme => ({
    contactInfoText: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.gray.main,
        marginBottom: theme.sizes.margin.m1,
    },

    contactDivider: {
        marginTop: theme.sizes.margin.m6,
        marginBottom: theme.sizes.margin.m3,
    },
    contactInformation: {
        marginBottom: theme.sizes.margin.m6,
    },
    container: { ...DefaultStyles.container },
    expansionPanel: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    dialogSubTitleText: {
        fontWeight: 500,
    },
    expansionPanelSummary: {
        padding: 0,
    },
    expansionPanelDetails: {
        padding: 0,
        display: 'block',
        marginBottom: theme.sizes.margin.m2,
    },
    expansionPanelDetailRowTitle: {
        marginTop: theme.sizes.margin.m2,
        marginBottom: theme.sizes.margin.m1,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
    },
    expansionPanelDetailRow: {
        display: 'flex',
        alignItems: 'center',
    },
    checkText: {
        marginLeft: theme.sizes.margin.m2,
    },
})

LessonsOverview.contextType = PageContext

export default withTranslation()(withStyles(styles)(LessonsOverview))
