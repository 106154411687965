import Ticket from './Models/Ticket'
import moment from 'moment'

const { getBadRequestError, getInvalidParameterError } = require('../Errors')

const todayDate = () => {
    const today = new Date()
    today.setMilliseconds(0)
    today.setSeconds(0)
    today.setMinutes(0)
    today.setHours(0)
    return today
}

export default class {
    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api
    }

    /**
     * Queries a specific ticket for the given client..
     * @returns Promise<Ticket>
     */
    getTicket(id) {
        const url = this.api.buildRequestURL('Tickets/{id}', {
            '{id}': id,
        })
        return this.api.request('GET', url, null).then(ticket => new Ticket(ticket))
    }

    /**
     * Queries the tickets for the given client.
     * @param {String} customerId
     * @param {Boolean} loadOutOfDateTickets
     * @returns Promise<Array<Ticket>>
     */
    getTicketsWithCustomerId(customerId, loadOutOfDateTickets = false) {
        const today = todayDate()
        const query = `?customerId=${customerId}&${loadOutOfDateTickets ? 'end' : 'start'}=${today.toISOString()}`
        const url = this.api.buildRequestURL('Tickets/getticketsfor' + query)
        //const query = `?expandlevels=false&expandFeedbacks=false&$filter=tolower(Client) eq tolower('${this.api.getClientName()}') and CustomerId eq ${customerId} and Dates/any(date: date/Start ${loadOutOfDateTickets ? 'lt' : 'gt'} ${today.toISOString()})`
        //const url = this.api.buildRequestURL('Tickets' + query)
        return this.api.request('GET', url, null).then(tickets => {
            return tickets.map(ticket => new Ticket(ticket))
        })
    }

    /**
     * Get tickets with family id
     * @param {String} familyId
     * @param {Boolean} loadOutOfDateTickets
     * @returns Promise<Array<Ticket>>
     */
    getTicketsWithFamilyId(familyId, loadOutOfDateTickets = false) {
        const today = todayDate()
        const query = `?familyId=${familyId}&${loadOutOfDateTickets ? 'end' : 'start'}=${today.toISOString()}`
        const url = this.api.buildRequestURL('Tickets/getticketsfor' + query)
        //const query = `?expandlevels=false&expandFeedbacks=false&$filter=tolower(Client) eq tolower('${this.api.getClientName()}') and Dates/any(date: date/Start ${loadOutOfDateTickets ? 'lt' : 'gt'} ${today.toISOString()}) and tolower(cast(FamilyId, 'Edm.String')) eq tolower(cast(${familyId}, 'Edm.String'))`
        //const url = this.api.buildRequestURL('Tickets' + query)
        return this.api.request('GET', url, null).then(tickets => {
            return tickets.map(ticket => new Ticket(ticket))
        })
    }

    /**
     * Updates a specific ticket.
     * @param {Ticket} ticket
     * @returns Promise<Ticket>
     */
    updateTicket(ticket) {
        if (!ticket) {
            return Promise.reject(getBadRequestError('ticket'))
        }
        const id = ticket.Id || ticket.getId()
        const url = this.api.buildRequestURL('Tickets/{id}', {
            '{id}': id,
        })
        return this.api.request('PUT', url, ticket).then(result => {
            return this.getTicket(id)
        })
    }

    /**
     * Updates a specific ticket.
     * @param {String} ticketId
     * @param {Object} feedbacksToUpdate
     * @returns Promise<Ticket>
     */
    updateProps(ticketId, feedbacksToUpdate) {
        if (!ticketId) {
            return Promise.reject(getBadRequestError('ticketId'))
        }
        if (!feedbacksToUpdate) {
            return Promise.reject(getBadRequestError('feedbacksToUpdate'))
        }

        const url = this.api.buildRequestURL('Tickets/{id}', {
            '{id}': ticketId,
        })

        return this.api.request('GET', url, null).then(ticketRaw => {
            const keysFeedbacksToUpdate = Object.keys(feedbacksToUpdate)
            if (keysFeedbacksToUpdate.length > 0 && ticketRaw.Feedbacks && ticketRaw.Feedbacks.length > 0) {
                for (let index = 0; index < ticketRaw.Feedbacks.length; index++) {
                    const feedback = ticketRaw.Feedbacks[index]
                    if (keysFeedbacksToUpdate.indexOf(feedback.OriginalTicketFeedbackId) >= 0) {
                        const feedbackValue = feedbacksToUpdate[feedback.OriginalTicketFeedbackId]
                        ticketRaw.Feedbacks[index] = { ...feedback, Rating: feedbackValue.rating, Comment: feedbackValue.comment || '', Enabled: false }
                    }
                }
            }
            ticketRaw['UpdatedAt'] = moment().format('YYYY-MM-DDTHH:mm:ss')
            return this.updateTicket(ticketRaw)
        })
    }
}
