import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PageContext } from '../../Context/PageProvider'
import { Button, Typography, Container, Grid } from '@material-ui/core'
import { ERROR } from '../../hgi-sa-api/Errors'
import AlertDialog from '../../Components/Commons/AlertDialog'
import { DefaultStyles } from '../../theme'
import Header from '../../Components/Header'
import Body from '../../Components/Body'
import Footer from '../../Components/Footer'
import UserActivation from '../../hgi-sa-api/UserService/Models/UserActivation'

class UserPassword extends Component {
    constructor(props) {
        super(props)
        const userActivation = props.location && props.location.state && props.location.state.userActivation ? new UserActivation(props.location.state.userActivation) : null
        this.state = {
            password: '',
            repeatPassword: '',
            userActivation,
            loading: false,
            errorMessage: null,
        }
    }

    componentDidMount() {
        const isActivationModeEnabled = this.context.api.isActivationModeEnabled()
        if (!isActivationModeEnabled) {
            const user = this.context.api.userService.getCurrentUser()
            if (!user) {
                const { history } = this.props
                history.push(this.context.getClientLink('login'))
                return
            }
        }
        ValidatorForm.addValidationRule('isPasswordMatch', value => {
            if (value !== this.state.password) {
                return false
            }
            return true
        })
    }

    componentWillUnmount() {
        if (ValidatorForm && ValidatorForm.removeValidationRule) {
            ValidatorForm.removeValidationRule('isPasswordMatch')
        }
    }

    onClickSubmit() {
        this.form.isFormValid(false).then(success => {
            if (success) {
                this.form.submit()
            }
        })
    }

    handleSubmit() {
        const { userActivation, password } = this.state
        const { t } = this.props
        const user = this.context.api.userService.getCurrentUser()

        this.setState({ loading: true })

        if (userActivation) {
            this.context.api.userService
                .activateUser(userActivation.setPassword(password))
                .then(result => {
                    this.setState({ loading: false })
                    if (userActivation.isResetPasswordMode()) {
                        this.context.api.setActivationModeEnabled(false)
                        window.location.href = '/'
                    } else {
                        //this.context.api.setActivationModeEnabled(false)
                        window.location.href = this.context.getClientLink('settings')
                    }
                })
                .catch(error => {
                    if (error.originError.name === ERROR.UNAUTHORIZED) {
                        this.setState({
                            errorMessage: userActivation.isResetPasswordMode() ? t('password_reset_error_description') : t('account_already_activated_log_in_or_reset_password'),
                            loading: false,
                        })
                    } else {
                        this.setState({ loading: false })
                        this.props.enqueueSnackbar(t('can_not_set_password', { message: error.getMessage() }), { variant: 'error', style: { whiteSpace: 'pre-line' } })
                    }
                })
        } else if (user) {
            this.context.api.userService
                .setPassword(user, password)
                .then(result => {
                    this.setState({ loading: false, password: '', repeatPassword: '' })
                    this.context.api.userService.logout()
                    this.props.enqueueSnackbar(t('password_changed'), { variant: 'default' })
                })
                .catch(error => {
                    this.setState({ loading: false })
                    this.props.enqueueSnackbar(t('can_not_change_password', { message: error.getMessage() }), { variant: 'error', style: { whiteSpace: 'pre-line' } })
                })
        } else {
            console.warn("can't save password, no logged in user found.")
            this.setState({ loading: false })
        }
    }

    render() {
        const { userActivation, password, repeatPassword, errorMessage } = this.state
        const { classes, t, history } = this.props
        const isActivationModeEnabled = this.context.api.isActivationModeEnabled()

        let title = isActivationModeEnabled ? t('hello') : t('change_password')
        let description = isActivationModeEnabled ? t('user_activation_password_description') : t('user_password_change_description')
        let buttonText = isActivationModeEnabled ? t('continue') : t('change_password')
        if (userActivation && userActivation.isResetPasswordMode()) {
            title = t('reset_password')
            description = t('user_password_change_description')
            buttonText = t('change_password')
        }

        return (
            <Container className={classes.container}>
                <Header />
                <Body>
                    <Typography className={classes.headerTitle} color="primary" variant="h1">
                        {title}
                    </Typography>
                    {description.split('\n').map((text, index) => (
                        <Typography gutterBottom={true} key={'user_password_change_description' + index} color="secondary" variant="body2">
                            {text}
                        </Typography>
                    ))}
                    <ValidatorForm ref={ref => (this.form = ref)} onSubmit={() => this.handleSubmit()}>
                        <Grid className={classes.formContainer} container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('password')}
                                    onChange={event => {
                                        this.setState({ password: event.target.value, error: null })
                                    }}
                                    name="password"
                                    type="password"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={password}
                                    fullWidth
                                    className={classes.passwordInput}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={t('repeat_password')}
                                    onChange={event => {
                                        this.setState({ repeatPassword: event.target.value, error: null })
                                    }}
                                    name="repeatPassword"
                                    type="password"
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={[t('this_field_is_required'), t('passwords_do_not_match')]}
                                    value={repeatPassword}
                                    fullWidth
                                    className={classes.passwordInput}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Body>
                <Footer>
                    <Grid container className={classes.footer}>
                        <Grid style={{ alignSelf: 'center' }} item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={6} align={'right'}>
                            <Button
                                onClick={() => this.onClickSubmit()}
                                className={classes.continueButton}
                                disabled={password.trim() === '' || repeatPassword.trim() === ''}
                                variant="contained"
                                color="primary">
                                {buttonText}
                            </Button>
                        </Grid>
                    </Grid>
                </Footer>
                <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} onSubmit={() => history.push(this.context.getClientLink('login'))} />
            </Container>
        )
    }
}

const styles = theme => ({
    container: { ...DefaultStyles.container },
    formContainer: {
        marginTop: theme.sizes.margin.m3,
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    headerTitle: { ...DefaultStyles.headerTitle },
    footer: {
        marginTop: theme.sizes.margin.m4,
        display: 'flex',
        alignItems: 'center',
    },
})

UserPassword.contextType = PageContext

export default withTranslation()(withStyles(styles)(withSnackbar(UserPassword)))
