import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Api from '../hgi-sa-api/Api'
import { DefaultStyles } from '../theme'
import DrawerMenu from './DrawerMenu'

const apiDomain = 'https://rccloud.hgisystems.com:5023'

//https://app.iweb-24.com/reset?UserId=8bac42fd-c45f-0748-9028-f9b41e5a7a47&clientId=d69fbf72-5e7f-4074-9938-f82c15fd6c82&username=info@webulos.com&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjhiYWM0MmZkLWM0NWYtMDc0OC05MDI4LWY5YjQxZTVhN2E0NyIsInJvbGUiOiJVc2VyIiwiY2xpZW50IjoiZmlzcyIsImlzYWN0aXZhdGVkIjoidHJ1ZSIsImlkZW50aWZpZXIiOiI5Y2U0NzU0Yi02MmFiLTQwN2UtOTU2MC1mMjg5ZDZkMTA3Y2MiLCJjdXN0b21lcmlkIjoiOGJhYzQyZmQtYzQ1Zi0wNzQ4LTkwMjgtZjliNDFlNWE3YTQ3IiwibmJmIjoxNTc2NTA0OTU2LCJleHAiOjE1NzkxODMzNTYsImlhdCI6MTU3NjUwNDk1Nn0.uxr-PxlOl0Wv-SkdsDoofYGlNtHdOTdCPiK6pvzKOdI

export const PageContext = React.createContext()

class PageProvider extends Component {
    constructor(props) {
        super(props)
        this.init()
    }

    async init() {
        this.api = new Api(apiDomain, false)
        this.api.userService.setLoginHandler(user => {
            this.setClient()
        })
        this.api.userService.setLogoutHandler(() => {
            const { history } = this.props
            this.setClient()
            history.push(this.getClientLink('login'))
        })

        this.state = {
            ready: false,
            drawerMenuopen: false,
            client: null,
        }

        this.api.userService.initSession().then(user => {
            this.setState({ ready: true })
            if (user) {
                this.setClient()
            }
        })
    }

    getClientLink(endpoint) {
        const clientId = this.api.getClientId()
        const clientName = this.api.getClientName()
        console.log('getClientLink', endpoint, `/${clientId}/${clientName}/${endpoint}`)
        return endpoint ? `/${clientName}/${clientId}/${endpoint}` : `/${clientName}/${clientId}`
    }

    setClient() {
        const user = this.api.userService.getCurrentUser()
        //console.log('setClient', user, this.state.client)
        if (!user && this.state.client) {
            this.setState({ client: null })
        } else if (user && !this.state.client) {
            const clientId = this.api.getClientId()
            this.api.clientService.getClient(clientId).then(client => {
                this.setState({ client: client })
            })
        }
    }

    render() {
        const { classes } = this.props
        const { ready, client } = this.state
        const isActivationModeEnabled = this.api.isActivationModeEnabled()
        const user = this.api.userService.getCurrentUser()
        const api = this.api
        return (
            <PageContext.Provider
                value={{
                    ready,
                    client,
                    api,
                    openDrawerMenu: () => this.setState({ drawerMenuopen: true }),
                    getClientLink: this.getClientLink.bind(this),
                }}>
                {ready && (
                    <div className={classes.root}>
                        {!isActivationModeEnabled && user && (
                            <DrawerMenu open={this.state.drawerMenuopen} onOpen={() => this.setState({ drawerMenuopen: true })} onClose={() => this.setState({ drawerMenuopen: false })} />
                        )}
                        {this.props.children}
                    </div>
                )}
            </PageContext.Provider>
        )
    }
}

const PageConsumer = PageContext.Consumer

const styles = theme => ({
    root: {
        ...DefaultStyles.AbsoluteFill,
    },
})

export default withTranslation()(withStyles(styles)(withRouter(PageProvider)))
export { PageConsumer }
