import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { withSnackbar } from 'notistack'
import { Link, Button, Divider, Typography, Container, FormGroup, FormControlLabel, FormControl, Switch, Grid } from '@material-ui/core'
import { DefaultStyles } from '../../theme'

import Header from '../../Components/Header'
import Body from '../../Components/Body'
import Footer from '../../Components/Footer'
import i18n from 'i18next'

const validateUrl = url => {
    if (url.substring(0, 7) !== 'http://' && url.substring(0, 8) !== 'https://') {
        url = 'http://' + url
    }
    return url
}

export class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            client: null,
            language: i18n.language.substr(0, 2),
            fields: [],
            values: {},
        }
    }

    componentDidMount() {
        this.loadSettings()
    }

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2),
                    groups: null,
                },
                () => this.loadSettings(),
            )
        }
    }

    loadSettings() {
        const setData = client => {
            const user = this.context.api.userService.getCurrentUser()
            const fields = client.getGdprFields(i18n.language.substr(0, 2))

            const values = {}
            fields &&
                fields.map(field => {
                    values[field.key] = user[field.key] || false
                })

            this.setState({
                client,
                fields,
                values,
                loading: false,
            })
        }

        if (this.context.client) {
            setData(this.context.client)
        } else {
            const clientId = this.context.api.getClientId()
            this.context.api.clientService.getClient(clientId).then(client => {
                setData(client)
            })
        }
    }

    onClickSubmit() {
        const { history, t } = this.props
        const { fields, values } = this.state

        this.setState({ loading: true })

        const user = this.context.api.userService.getCurrentUser()
        fields &&
            fields.map(field => {
                user[field.key] = values[field.key]
            })

        this.context.api.userService
            .updateUser(user)
            .then(user => {
                const isActivationModeEnabled = this.context.api.isActivationModeEnabled()
                if (isActivationModeEnabled) {
                    history.push(this.context.getClientLink('profile'))
                } else {
                    this.setState({ loading: false })
                    this.props.enqueueSnackbar(t('settings_saved'), { variant: 'default' })
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar(t('can_not_save_settings', { message: error.getMessage() }), { variant: 'error', style: { whiteSpace: 'pre-line' } })
                this.setState({ loading: false })
            })
    }

    render() {
        const { loading, fields, values } = this.state
        const { classes, t } = this.props

        let canSubmit = true
        fields &&
            fields.map(field => {
                if (field.isRequired && !values[field.key]) {
                    canSubmit = false
                }
            })

        const isActivationModeEnabled = this.context.api.isActivationModeEnabled()

        return (
            <Container className={classes.container}>
                <Header />
                <Body>
                    <Typography className={classes.headerTitle} color="primary" variant="h1">
                        {t('data_protection')}
                    </Typography>
                    {t('data_protection_description')
                        .split('\n')
                        .map((text, index) => (
                            <Typography gutterBottom key={'data_protection_description' + index} color="secondary" variant="body2">
                                {text}
                            </Typography>
                        ))}
                    <FormControl classes={{ root: classes.formControl }} fullWidth component="fieldset">
                        <FormGroup aria-label="position">
                            {fields &&
                                fields.map(field => (
                                    <Fragment>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            InputProps={{ classes: { root: classes.switchText } }}
                                            classes={{ root: classes.switchText }}
                                            control={
                                                <Switch
                                                    color="primary"
                                                    disabled={loading}
                                                    onChange={(event, value) => {
                                                        values[field.key] = value
                                                        this.setState({
                                                            values,
                                                        })
                                                    }}
                                                    checked={values[field.key]}
                                                />
                                            }
                                            label={field.title}
                                            labelPlacement="start"
                                        />
                                        {field.link && (
                                            <Link
                                                className={classes.privacyInformation}
                                                href={validateUrl(field.link.href)}
                                                target="_blank"
                                                rel="noreferrer"
                                                color="inherit"
                                                variant="inherit"
                                                gutterBottom>
                                                {t(field.link.title)}
                                            </Link>
                                        )}
                                        <Divider classes={{ root: classes.divider }} />
                                    </Fragment>
                                ))}
                            <Typography className={classes.formControl} classes={{ root: classes.privacyInformationNote }} color="secondary" gutterBottom variant="body2">
                                {t('privacy_information_note')}
                            </Typography>
                        </FormGroup>
                    </FormControl>
                </Body>
                <Footer>
                    <Grid container spacing={3}>
                        <Grid item sm={8} xs={12}>
                            <Typography classes={{ root: classes.privacyInformationSubNote }} color="secondary" gutterBottom>
                                {t('privacy_information_sub_note')}
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12} align={'right'}>
                            <Button disabled={!canSubmit || loading} onClick={() => this.onClickSubmit()} className={classes.continueButton} variant="contained" color="primary">
                                {isActivationModeEnabled ? t('continue') : t('save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Footer>
            </Container>
        )
    }
}

const styles = theme => ({
    container: { ...DefaultStyles.container },
    headerTitle: { ...DefaultStyles.headerTitle },
    switchText: {
        color: theme.palette.secondary.main,
        marginLeft: 0,
    },
    formControl: {
        marginTop: theme.sizes.margin.m3,
        marginBottom: theme.sizes.margin.m3,
    },
    // divider: {
    // 	marginTop: theme.sizes.margin.m3,
    // 	marginBottom: theme.sizes.margin.m3
    // },
    privacyInformation: {
        fontWeight: 500,
        textTransform: 'none',
    },
    privacyInformationSubNote: {
        fontWeight: 500,
        fontSize: theme.typography.body3.fontSize,
        marginBottom: theme.sizes.margin.m3,
        lineHeight: 1.2,
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
})

Settings.contextType = PageContext

export default withTranslation()(withStyles(styles)(withSnackbar(Settings)))
