import React, { PureComponent, Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'

import ReactSVG from 'react-svg'

class ContactInformation extends PureComponent {
    render() {
        const { classes, t } = this.props
        const client = this.context.client

        if (!client) {
            return null
        }

        const phone = client.getPhoneNumber()

        return (
            <Fragment>
                {phone && (
                    <div className={classes.contactInfo}>
                        <ReactSVG
                            beforeInjection={svg => {
                                svg.setAttribute('style', 'width: 20px; height: 20px')
                            }}
                            src={'/assets/icons/dark/phone.svg'}
                        />
                        <Typography className={classes.contactText}>{phone}</Typography>
                    </div>
                )}
            </Fragment>
        )
    }
}

ContactInformation.propTypes = {}

const styles = theme => ({
    contactInfo: {
        display: 'flex',
    },

    contactText: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: '600',
        color: theme.palette.gray.main,
        marginLeft: theme.sizes.margin.m2,
        marginBottom: theme.sizes.margin.m1,
    },
})

ContactInformation.contextType = PageContext

export default withTranslation()(withStyles(styles)(ContactInformation))
