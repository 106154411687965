import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import App from './App'
import theme from './theme'

// import i18n (needs to be bundled )
import './i18n'

import packageJson from '../package.json'
console.log('SkischulAssistant App Version: ', packageJson.version)

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>,
    document.querySelector('#root'),
)

// Test clientId: d69fbf72-5e7f-4074-9938-f82c15fd6c82
// Test client: fiss

// #### TEST USER 1 ####
// Test username info@webulos.com
// Test password test
// entry point to login: https://app.iweb-24.com/fiss/d69fbf72-5e7f-4074-9938-f82c15fd6c82
// entry point to activate: https://app.iweb-24.com/activate?UserId=8BAC42FD-C45F-0748-9028-F9B41E5A7A47&clientId=d69fbf72-5e7f-4074-9938-f82c15fd6c82&username=8BAC42FDC45F07489028F9B41E5A7A47&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjhiYWM0MmZkLWM0NWYtMDc0OC05MDI4LWY5YjQxZTVhN2E0NyIsInJvbGUiOiJVc2VyIiwiY2xpZW50IjoiZmlzcyIsImlzYWN0aXZhdGVkIjoiZmFsc2UiLCJpZGVudGlmaWVyIjoiZDc2YTgxZmMtYjY2MC00M2I2LTg4YjYtZTU1Y2Q5MWU2ODVjIiwiY3VzdG9tZXJpZCI6IjhiYWM0MmZkLWM0NWYtMDc0OC05MDI4LWY5YjQxZTVhN2E0NyIsIm5iZiI6MTU3NjEzOTU1OCwiZXhwIjoxNTc4ODE3OTU4LCJpYXQiOjE1NzYxMzk1NTh9.10Esj9a-Bre97Tfk_RsHowZPjhhuJx_Aw02XJQ76Cr8

// #### TEST USER 2 ####
// Test username nejdet@webulos.com
// Test password test
// entry point to login: https://app.iweb-24.com/fiss/d69fbf72-5e7f-4074-9938-f82c15fd6c82
// entry point to activate: https://app.iweb-24.com/activate?UserId=DDA7CB18-DEA5-EC4C-9AC1-08777DCF0D63&clientId=d69fbf72-5e7f-4074-9938-f82c15fd6c82&username=DDA7CB18DEA5EC4C9AC108777DCF0D63&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImRkYTdjYjE4LWRlYTUtZWM0Yy05YWMxLTA4Nzc3ZGNmMGQ2MyIsInJvbGUiOiJVc2VyIiwiY2xpZW50IjoiZmlzcyIsImlzYWN0aXZhdGVkIjoiZmFsc2UiLCJpZGVudGlmaWVyIjoiZmUyOTU0NGYtMzYzMS00YzM4LWEyZDktZDg5NDA5NDU3ODVjIiwiY3VzdG9tZXJpZCI6ImRkYTdjYjE4LWRlYTUtZWM0Yy05YWMxLTA4Nzc3ZGNmMGQ2MyIsIm5iZiI6MTU3NjEzNzk2NSwiZXhwIjoxNTc4ODE2MzY1LCJpYXQiOjE1NzYxMzc5NjV9.iejwnPsULpawiBdBKBVoRQeC4-PqKp0olKmeCEYtz8s

// #### TEST USER 3 ####
// Test username gerasis@hgisystems.com
// Test password 1234
// entry point to login: https://app.iweb-24.com/fiss/d69fbf72-5e7f-4074-9938-f82c15fd6c82
