import User from './Models/User'
import ls from 'local-storage'
const jwtDecode = require('jwt-decode')
const moment = require('moment')

const TOKEN_STORAGE_KEY = 'hgi-sa-api-token'
const CLIENT_ID_STORAGE_KEY = 'hgi-sa-api-client-id'
const { getBadRequestError, getInvalidParameterError, ERROR } = require('../Errors')

export default class {
    /**
     * @param {Api} api
     * @param {Function} onLogin
     * @param {Function} onLogout
     */
    constructor(api) {
        this.api = api
        this.onLogin = null
        this.onLogout = null
        this.user = null

        this.loadUser = () => {
            return new Promise((resolve, reject) => {
                if (this.isTokenValid()) {
                    const props = jwtDecode(this.api.getToken())
                    this.getUser(props.client, props.id)
                        .then(user => {
                            this.user = user
                            resolve(user)
                        })
                        .catch(error => {
                            console.warn('getUser, error', error)
                            if (error && error.originError) {
                                if (error.originError.name === ERROR.UNAUTHORIZED) {
                                    console.warn('token is no more valid, logout current user')
                                    this.logout()
                                }
                            }
                            resolve()
                        })
                } else {
                    resolve(null)
                }
            })
        }
    }

    /**
     * set login handler
     * @param {Function} onLogin
     */
    setLoginHandler(onLogin) {
        this.onLogin = onLogin
    }

    /**
     * set logout handler
     * @param {Function} onLogout
     */
    setLogoutHandler(onLogout) {
        this.onLogout = onLogout
    }

    /**
     * Is token valid
     * @returns {Boolean}
     */
    isTokenValid() {
        if (this.api.getToken() /* && this.api.getClientId() */) {
            const decodedToken = jwtDecode(this.api.getToken())
            if (decodedToken) {
                const tokenExpire = new Date(Number(decodedToken.exp) * 1000)
                const diff = moment(tokenExpire).diff(moment())
                if (diff > 0) {
                    return true
                } else {
                    ls.remove(TOKEN_STORAGE_KEY)
                    this.api.setToken(null)
                    this.user = null
                }
            }
        }
        return false
    }

    /**
     * Get current user
     * @returns {User}
     */
    getCurrentUser() {
        if (this.isTokenValid()) {
            return this.user
        }
        return null
    }

    /**
     * Get current user
     * @returns {User}
     */
    getCustomerIdFromCurrentUser() {
        if (this.isTokenValid()) {
            const props = jwtDecode(this.api.getToken())
            return props.customerid
        }
        return null
    }

    /**
     * init session
     * @returns {Promise}
     */
    initSession() {
        return this.loadUser()
    }

    /**
     * logout
     * @returns {undefined}
     */
    logout() {
        this.api.setToken(null)
        this.user = null
        if (this.onLogout) {
            this.onLogout()
        }
    }

    /**
     * logout
     * @returns {undefined}
     */
    silentLogout() {
        this.api.setToken(null)
        this.user = null
    }

    /**
     * Authenticates a user
     * @param {String} username (required)
     * @param {String} password (required)
     *
     * @returns Promise<User>
     */
    authUser(username, password) {
        if (!username) {
            return Promise.reject(getBadRequestError('username'))
        }
        if (!password) {
            return Promise.reject(getBadRequestError('password'))
        }
        const url = this.api.buildRequestURL('Users/login', {})
        const body = { Username: username, Password: password, Client: this.api.getClientName() }
        return this.api.request('POST', url, body).then(session => {
            const token = session.T
            this.api.setToken(token)
            return this.loadUser()
        })
    }

    /**
     * Reset user password
     * @param {String} email (required)
     *
     * @returns Promise<undefinied>
     */
    resetUserPassword(email) {
        if (!email) {
            return Promise.reject(getBadRequestError('email'))
        }
        const url = this.api.buildRequestURL('Users/resetpassword', {})
        const body = { Email: email, Client: this.api.getClientName() }
        return this.api.request('POST', url, body)
    }

    /**
     * Queries a specific user for the given client.
     * @param {String} client (required)
     * @param {String} userId (required)
     * @returns Promise<User>
     */
    getUser(client, userId) {
        if (!client) {
            return Promise.reject(getBadRequestError('client'))
        }
        if (!userId) {
            return Promise.reject(getBadRequestError('userId'))
        }
        const url = this.api.buildRequestURL('Users/{client}/{id}', {
            '{client}': client,
            '{id}': userId,
        })
        return this.api.request('GET', url, null).then(user => {
            return new User(user)
        })
    }

    /**
     * activate user
     * @param {UserActivation} userActivation
     * @param {String} userId
     * @param {String} username
     * @param {String} password
     *
     */
    activateUser(userActivation) {
        if (!userActivation.getUserId()) {
            return Promise.reject(getBadRequestError('userId'))
        }
        if (!userActivation.getUsername()) {
            return Promise.reject(getBadRequestError('username'))
        }
        if (!userActivation.getPassword() || userActivation.getPassword().trim() === '') {
            return Promise.reject(getBadRequestError('password'))
        }
        const url = this.api.buildRequestURL('Users/reset')
        const body = {
            Client: this.api.getClientName(),
            Id: userActivation.getUserId(),
            Username: userActivation.getUsername(),
            Password: userActivation.getPassword(),
        }
        return this.api.request('POST', url, body, null, userActivation.getToken()).then(response => {
            return this.authUser(userActivation.getUsername(), userActivation.getPassword())
        })
    }

    /**
     * set user password
     * @param {User} user
     * @param {String} password
     *
     */
    setPassword(user, password) {
        if (!user || !user.getId()) {
            return Promise.reject(getBadRequestError('userId'))
        }
        if (!password || password.trim() === '') {
            return Promise.reject(getBadRequestError('password'))
        }
        const url = this.api.buildRequestURL('Users/{id}', {
            '{id}': user.getId(),
        })
        const body = {
            Client: this.api.getClientName(),
            Id: user.getId(),
            Password: password,
        }
        return this.api.request('PUT', url, body).then(result => {
            return result
        })
    }

    /**
     * Set username
     * @param {User} user
     * @param {String} username
     * @returns Promise<User>
     */
    updateUsername(user, username) {
        if (!user) {
            return Promise.reject(getBadRequestError('user'))
        }
        if (!username) {
            return Promise.reject(getBadRequestError('username'))
        }
        const url = this.api.buildRequestURL('Users/{id}', {
            '{id}': user.getId(),
        })
        const body = {
            Client: this.api.getClientName(),
            Id: user.getId(),
            Username: username,
        }
        return this.api.request('PUT', url, body).then(result => {
            return result
        })
    }

    /**
     * Updates a specific user.
     * @param {User} user
     * @returns Promise<User>
     */
    updateUser(user) {
        if (!user) {
            return Promise.reject(getBadRequestError('user'))
        }
        const url = this.api.buildRequestURL('Users/{id}', {
            '{id}': user.getId(),
        })
        return this.api.request('PUT', url, user.buildApiRequestBody()).then(result => {
            return result
        })
    }
}
