import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PageContext } from '../../Context/PageProvider'
import { Button, Typography, Container, Grid } from '@material-ui/core'
import { DefaultStyles } from '../../theme'
import AlertDialog from '../../Components/Commons/AlertDialog'
import { ERROR } from '../../hgi-sa-api/Errors'
import Header from '../../Components/Header'
import Body from '../../Components/Body'

class PasswordResetRequest extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            loading: false,
            errorMessage: null,
            showSusccessAlert: false,
        }
    }

    onClickSubmit() {
        const { email } = this.state
        const { history, t } = this.props

        this.setState({ loading: true }, () => {
            this.context.api.userService
                .resetUserPassword(email)
                .then(result => {
                    this.setState({ showSusccessAlert: true })
                })
                .catch(error => this.setError(error))
        })
    }

    setError(error) {
        const { t } = this.props
        let message = t('error_unknown')
        if (error && error.originError) {
            console.log('error.originError.name', error.originError.name)
            if (error.originError.name === ERROR.NOT_FOUND || error.originError.name === ERROR.UNAUTHORIZED) {
                message = t('error_invalid_username')
            }
        }
        this.setState({ errorMessage: message, loading: false })
    }

    onClickBack() {
        const { history } = this.props
        history.push(this.context.getClientLink('login'))
    }

    render() {
        const { email, loading, showSusccessAlert, errorMessage } = this.state
        const { classes, history, t } = this.props

        return (
            <Container className={classes.container}>
                <Header />
                <Body maxWidth={'xs'}>
                    <Typography className={classes.headerTitle} color="primary" variant="h1">
                        {t('reset_password')
                            .split('\n')
                            .map(item => (
                                <div>{item}</div>
                            ))}
                    </Typography>
                    <Typography gutterBottom={true} color="secondary" variant="body2">
                        {t('reset_password_description')}
                    </Typography>
                    <ValidatorForm>
                        <Grid className={classes.formContainer} container direction="column" alignItems="stretch" justify="flex-start" spacing={3}>
                            <Grid item>
                                <TextValidator
                                    label={t('email')}
                                    onChange={event => {
                                        this.setState({ email: event.target.value, errorMessage: null })
                                    }}
                                    name="email"
                                    type="text"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    value={email}
                                    fullWidth
                                    className={classes.input}
                                />
                            </Grid>
                            {errorMessage && (
                                <Grid item>
                                    <Typography variant="body2" color="error" className={classes.errorMessage}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item className={classes.footer}>
                                <Button disabled={loading} fullWidth onClick={() => this.onClickSubmit()} disabled={email.trim() === ''} variant="contained" color="primary">
                                    {t('reset_password')}
                                </Button>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Button fullWidth onClick={() => this.onClickBack()}>
                                    {t('back')}
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                    <AlertDialog
                        open={showSusccessAlert}
                        title={t('password_reset_successfully_message_title')}
                        message={t('password_reset_successfully_message_description')}
                        onSubmit={() => history.push(this.context.getClientLink('login'))}
                    />
                </Body>
            </Container>
        )
    }
}

const styles = theme => ({
    container: { ...DefaultStyles.container },
    formContainer: {
        marginTop: theme.sizes.margin.m3,
    },
    headerTitle: { ...DefaultStyles.headerTitle },
    footer: {
        marginTop: theme.sizes.margin.m4,
    },
})

PasswordResetRequest.contextType = PageContext

export default withTranslation()(withStyles(styles)(PasswordResetRequest))
