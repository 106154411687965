import { Route, Redirect } from 'react-router-dom'
import React, { useContext } from 'react'
import { PageContext } from './Context/PageProvider'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const context = useContext(PageContext)
    return <Route {...rest} render={props => (context.api.userService.getCurrentUser() ? <Component {...rest} {...props} context={context} /> : <Redirect to={context.getClientLink('login')} />)} />
}

export default ProtectedRoute
