import { isoStringToDate } from '../../ApiHelper'

export default class TicketDate {
	
    /**
     * @param {Object<String,String,String,String,String}
     */

	constructor({ Instructor, Name, MeetingTime, Start, End }) {
		this.instructor = Instructor
		this.name = Name
		this.meetingTime = MeetingTime
		this.start = isoStringToDate(Start)
		this.end = isoStringToDate(End)
	}

	/**
	*  is instructor
	* @returns {String}
	*/
	getInstructor() {
		return this.instructor
	}

	/**
	*  is name
	* @returns {String}
	*/
	getName() {
		return this.name
	}

	/**
	*  is meeting time
	* @returns {String}
	*/
	getMeetingTime() {
		return this.meetingTime
	}

	/**
	*  is start
	* @returns {String}
	*/
	getStart() {
		return this.start
	}

	/**
	*  is end
	* @returns {String}
	*/
	getEnd() {
		return this.end
	}

}