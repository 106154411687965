import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import TicketCard, { COURSE_TYPE } from './TicketCard'
import PropTypes from 'prop-types'

class TicketGroups extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { classes, t, tickets, groupType, onClickTicket } = this.props

        if (!tickets || tickets.length === 0) return null

        let groupHeaderText
        let groupHeader
        if (groupType === COURSE_TYPE.GROUP) {
            groupHeaderText = t('group_courses')
            groupHeader = [classes.groupTitle, classes.groupCourseBackground].join(' ')
        } else if (groupType === COURSE_TYPE.PRIVATE) {
            groupHeaderText = t('private_courses')
            groupHeader = [classes.groupTitle, classes.privateCourseBackground].join(' ')
        } else if (groupType === COURSE_TYPE.LUNCH) {
            groupHeaderText = t('midday_care')
            groupHeader = [classes.groupTitle, classes.lunchCourseBackground].join(' ')
        }

        return (
            <Grid container className={classes.groupsTypeColumn} spacing={2}>
                <Grid item md={12}>
                    <Typography className={groupHeader} variant="h6">
                        {groupHeaderText}
                    </Typography>
                </Grid>
                {tickets.map((ticket, i) => (
                    <Grid key={'ticket_card' + i} item xs={12} md={6}>
                        <TicketCard onClickTicket={ticket => onClickTicket(ticket)} ticket={ticket} />
                    </Grid>
                ))}
            </Grid>
        )
    }
}

TicketGroups.propTypes = {
    ticket: PropTypes.func,
    onClickTicket: PropTypes.func,
}

const styles = theme => ({
    groupsTypeColumn: {
        marginTop: theme.sizes.margin.m4,
    },
    groupTitle: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.white.main,
        borderRadius: 2,
        textTransform: 'uppercase',
        width: 'fit-content',
        paddingLeft: theme.sizes.padding.p1,
        paddingRight: theme.sizes.padding.p1,
        marginBottom: theme.sizes.margin.m3,
        marginTop: theme.sizes.margin.m3,
    },
    groupCourseBackground: {
        backgroundColor: theme.palette.blue.main,
    },
    privateCourseBackground: {
        backgroundColor: theme.palette.whiteBlue.main,
    },
    lunchCourseBackground: {
        backgroundColor: theme.palette.orange.main,
    },
})

export default withTranslation()(withStyles(styles)(TicketGroups))
